import { chainNetworkTypeSchema, trasformUndefined } from '@apeiron/library'
import {
  ExpeditionClaimableStatus,
  ExpeditionStakeType,
  ExpeditionStatus,
  StarTreasureType,
  TreasureBoxInfoType,
  TreasureKeyType
} from '@src/constants/expedition'
import { apostleTicketSchema } from '@src/deserialize/yup/apostle'
import {
  Expedition,
  ExpeditionGroupInfo,
  ExpeditionLite,
  ExpeditionStakeSelectLimit,
  ExpeditionStakeSetting,
  ExpeditionSupportedToken,
  TreasureBox,
  TreasureBoxInfo,
  TreasureBoxOtherInfo,
  TreasureBoxPresaleStarInfo,
  TreasureKey
} from '@src/types/expedition'
import * as R from 'ramda'
import * as yup from 'yup'

const expeditionStatusSchema: yup.BaseSchema<ExpeditionStatus> = yup
  .mixed()
  .oneOf(Object.values(ExpeditionStatus))
  .default(ExpeditionStatus.UNKNOWN)

const expeditionStakeTypeSchema: yup.BaseSchema<ExpeditionStakeType> = yup
  .mixed()
  .oneOf(Object.values(ExpeditionStakeType))
  .default(ExpeditionStakeType.Planet)

const treasureKeyTypeSchema: yup.BaseSchema<TreasureKeyType> = yup
  .mixed()
  .oneOf(Object.values(TreasureKeyType))
  .default(TreasureKeyType.Cosmic)

const treasureBoxInfoTypeSchema: yup.BaseSchema<TreasureBoxInfoType> = yup
  .mixed()
  .oneOf(Object.values(TreasureBoxInfoType))
  .default(TreasureBoxInfoType.PrescaleStar)

const starTreasureTypeSchema: yup.BaseSchema<StarTreasureType> = yup
  .mixed()
  .oneOf(Object.values(StarTreasureType))
  .default(StarTreasureType.Regular)

const expeditionSupportedTokenSchema: yup.SchemaOf<ExpeditionSupportedToken> =
  yup
    .object({
      convertKeyTokenTypes: yup.array().default([]),
      extraVaporTokenTypes: yup.array().default([])
    })
    .from('exchangeKeyTokens', 'convertKeyTokenTypes')
    .from('extraVaporStakeTokens', 'extraVaporTokenTypes')

const treasureKeySchema: yup.SchemaOf<TreasureKey> = yup.object({
  image: yup.string().default(''),
  name: yup.string().default(''),
  type: treasureKeyTypeSchema
})

const treasureBoxPresaleStarInfo: yup.SchemaOf<TreasureBoxPresaleStarInfo> = yup
  .object({
    agingSpeed: yup.string().default(''),
    customizations: yup.string().default(''),
    customizationTier: yup.string().default(''),
    rarity: yup.string().default(''),
    trackCount: yup.string().default('')
  })
  .camelCase()
  .noUnknown(true)

const treasureBoxOtherInfo: yup.SchemaOf<TreasureBoxOtherInfo> = yup
  .object({})
  .camelCase()
  .noUnknown(true)

const treasureBoxInfoSchema: yup.SchemaOf<TreasureBoxInfo> = yup.object({
  description: yup.string().default(''),
  detail: yup
    .mixed()
    .when(['type'], (type: TreasureBoxInfoType) => {
      switch (type) {
        case TreasureBoxInfoType.PrescaleStar:
          return treasureBoxPresaleStarInfo
        case TreasureBoxInfoType.Item:
        default:
          return treasureBoxOtherInfo.nullable(true).default(null)
      }
    })
    .nullable(true)
    .default(null),
  image: yup.string().default(''),
  name: yup.string().default(''),
  quantity: yup.number().default(0),
  type: treasureBoxInfoTypeSchema,
  uid: yup.string().default('')
})

const treasureBoxSchema: yup.SchemaOf<TreasureBox> = yup
  .object({
    color: yup.string().default('white'),
    image: yup.string().default(''),
    infoList: yup.array().of(treasureBoxInfoSchema).default([]),
    name: yup.string().default(''),
    numberOfBox: yup.number().default(0),
    requiredKey: yup.number().default(0),
    stType: starTreasureTypeSchema,
    uid: yup.string().default('')
  })
  .from('previewInfoKeys', 'infoList')
  .from('requireStakeKey', 'requiredKey')
  .from('stDescriptionKey.color', 'color')
  .from('stDescriptionKey.image', 'image')
  .from('stDescriptionKey.name', 'name')
  .from('stDescriptionKey.uid', 'uid')
  .from('totalRewardTreasure', 'numberOfBox')
  .noUnknown(true)

const expeditionShareSchema: yup.SchemaOf<ExpeditionLite> = yup
  .object({
    chainId: yup.string().default('0'),
    endTime: yup.string().default(''),
    icon: yup.string().default(''),
    name: yup.string().default(''),
    startTime: yup.string().default(''),
    type: yup.number().default(1)
  })
  .from('chainExpeditionID', 'chainId')
  .from('expeditionEndTime', 'endTime')
  .from('expeditionStartTime', 'startTime')
  .from('expeditionType', 'type')
  .from('staticAssetKey.image.event_icon', 'icon')

const selectLimitSchema: yup.SchemaOf<ExpeditionStakeSelectLimit> = yup
  .object({
    type: yup.mixed().when(['$data'], (data: Record<string, any>) => {
      const stakeType = R.pathOr(
        ExpeditionStakeType.UNKNOWN,
        ['optionalAsset', 'stakeType'],
        data
      ) as ExpeditionStakeType

      switch (stakeType) {
        case ExpeditionStakeType.ApostleTicket:
          return apostleTicketSchema

        case ExpeditionStakeType.Planet:
        case ExpeditionStakeType.Apostle:
        default:
          return yup.string().default('')
      }
    }),
    limit: yup.number().default(0)
  })
  .from('supportType', 'type')
  .noUnknown(true)

const stakeSettingSchema: yup.SchemaOf<ExpeditionStakeSetting> = yup
  .object({
    min: yup.number().default(0),
    max: yup.number().default(0),
    type: expeditionStakeTypeSchema,
    allowPlanetStage: yup
      .array()
      .of(yup.string().default(''))
      .default([])
      .nullable(),
    selectLimit: yup.array().of(selectLimitSchema).default([])
  })
  .from('maxAmount', 'max')
  .from('minAmount', 'min')
  .from('stakeType', 'type')
  .from('supportTypes', 'selectLimit')
  .noUnknown(true)

export const expeditionLiteSchema: yup.SchemaOf<ExpeditionLite> =
  expeditionShareSchema.noUnknown(true)

export const expeditionGroupInfoSchema: yup.SchemaOf<ExpeditionGroupInfo> =
  yup.object({
    grouped: yup.boolean().default(true),
    quantity: yup.number().default(0)
  })

export const claimableStatusSchema: yup.SchemaOf<ExpeditionClaimableStatus> =
  yup
    .mixed()
    .oneOf(Object.values(ExpeditionClaimableStatus))
    .default(ExpeditionClaimableStatus.Open)

export const expeditionSchema: yup.SchemaOf<Expedition> = expeditionShareSchema
  .concat(
    yup.object({
      background: yup
        .string()
        .default(
          'https://nftprops-staging.apeironnft.com/static/artworks/background-event-star.jpg'
        ),
      completeAvatar: yup.string().default(''),
      completed: yup.boolean().default(false),
      description: yup.string().default(''),
      groupInfo: expeditionGroupInfoSchema
        .transform(trasformUndefined)
        .default({ grouped: false, quantity: 0 }),
      menuItemStepAsset: yup
        .array()
        .of(
          yup.object({
            icon: yup.string().default(''),
            doneIcon: yup.string().default(''),
            text: yup.string().default(''),
            step: yup.number().default(0)
          })
        )
        .default([]),
      network: chainNetworkTypeSchema,
      optionalStakeSetting: stakeSettingSchema,
      requiredStakeSetting: stakeSettingSchema,
      derivedStatus: expeditionStatusSchema,
      supportedToken: expeditionSupportedTokenSchema,
      themeColor: yup.string().default('#262829'),
      treasureBoxes: yup.array().of(treasureBoxSchema).default([]),
      treasureBoxTotal: yup
        .number()
        .when(['$data'], (data: Record<string, any>) => {
          const countTotalBox = R.pipe<any, any[], number[], number>(
            R.propOr({}, 'treasurePool'),
            items => R.pluck('totalRewardTreasure', items),
            R.sum
          )

          return yup.number().default(countTotalBox(data))
        })
        .default(0),
      treasureKey: treasureKeySchema.nullable().default(null),
      user: yup.mixed().default(null),
      claimableStatus: claimableStatusSchema
    })
  )
  .from('groupedTreasure', 'groupInfo')
  .from('optionalAsset', 'optionalStakeSetting')
  .from('requiredAsset', 'requiredStakeSetting')
  .from('requireStakeKey', 'treasureKey')
  .from('staticAssetKey.image.background', 'background')
  .from('staticAssetKey.image.complete_avatar', 'completeAvatar')
  .from('staticAssetKey.theme_color', 'themeColor')
  .from('tokenRelated', 'supportedToken')
  .from('treasurePool', 'treasureBoxes')
  .from('menuItemStepAssetKeys', 'menuItemStepAsset')
  .noUnknown(true)
