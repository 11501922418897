import { CurrencyTokenType, currencyTokenTypeSchema } from '@apeiron/library'
import { ListingStatus, RoninOrderStatus } from '@src/constants/listingInfo'
import {
  ListingInfo,
  NFT1155ListingInfo,
  RoninSaleOrder
} from '@src/types/listingInfo'
import * as R from 'ramda'
import * as yup from 'yup'

export const listingStatusSchema: yup.BaseSchema<ListingStatus> = yup
  .mixed()
  .oneOf(Object.values(ListingStatus))
  .default(ListingStatus.UNLISTING)

const roninOrderStatusSchema: yup.BaseSchema<RoninOrderStatus> = yup
  .mixed()
  .oneOf(Object.values(RoninOrderStatus))
  .default(RoninOrderStatus.Expired)

export const roninSaleOrderSchema: yup.BaseSchema<RoninSaleOrder> = yup.object({
  kind: yup.number().default(0),
  orderKindEnum: yup.string().default(''),
  asset: yup
    .object({
      erc: yup.number().default(1),
      addr: yup.string().default(''),
      id: yup.string().default(''),
      quantity: yup.string().default('')
    })
    .default(null),
  expiredAt: yup.date().default(null),
  paymentToken: yup.string().default(''),
  startedAt: yup.date().default(null),
  basePrice: yup.string().default(''),
  endedAt: yup.date().default(null),
  endedPrice: yup.string().default(''),
  expectedState: yup.string().default(''),
  nonce: yup.number().default(0),
  marketFeePercentage: yup.number().default(0),
  signature: yup.string().default(''),
  orderStatus: roninOrderStatusSchema
})

export const listingInfoSchema: yup.SchemaOf<ListingInfo> = yup
  .object({
    currencyType: currencyTokenTypeSchema.default(CurrencyTokenType.Ron),
    duration: yup.number().default(0),
    endPrice: yup.number().default(0),
    startDate: yup.date().default(null),
    startPrice: yup.number().default(0),
    roninSaleOrder: roninSaleOrderSchema.default(null)
  })
  .from('currencyCode', 'currencyType')

export const listingInfoForNFT1155Schema: yup.SchemaOf<NFT1155ListingInfo> = yup
  .object({
    currencyType: currencyTokenTypeSchema,
    duration: yup.number().default(0),
    endPrice: yup.number().default(0),
    index: yup.number().default(0),
    quantity: yup.number().default(0),
    startDate: yup.date().default(null),
    startPrice: yup.number().default(0),
    roninSaleOrder: roninSaleOrderSchema
  })
  .when(['$data'], (data: Record<string, any>) => {
    return yup.object().transform(() => {
      return {
        duration: R.pathOr(0, ['listingInfo', 'duration'], data),
        endPrice: R.pathOr(0, ['listingInfo', 'endPrice'], data),
        index: R.propOr(0, 'listingIndex', data),
        quantity: R.propOr(0, 'qty', data),
        startDate: R.pathOr(0, ['listingInfo', 'startDate'], data),
        startPrice: R.pathOr(0, ['listingInfo', 'startPrice'], data),
        roninSaleOrder: R.pathOr(null, ['listingInfo', 'roninSaleOrder'], data)
      }
    })
  })
