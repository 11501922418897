import { NextImage, NextLink } from '@apeiron/library'
import { AppBar, Box, Container, styled, Toolbar } from '@mui/material'
import LogoJPG from '@public/images/image-apeiron-icon-v2.jpg'
import { ROUTE } from '@src/constants/route'
import { FC, ReactNode } from 'react'

const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  background: #3b3f53;
`

const StyledContainer = styled(Container)`
  box-shadow: none;
  padding: 0px;
`

const StyledToolbar = styled(Toolbar)`
  box-shadow: none;
  padding: 0px;
  gap: 20px;
  ${props => props.theme.breakpoints.up('res1024')} {
    padding: 0px;
  }

  border-bottom: 5px solid #c6af8f;
  height: 56px;
`

const Logo = styled(NextImage)`
  height: 100%;
`

const LogoContainer = styled(Box)`
  background-color: #e6e6e6;
  clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 100%, 100% 100%, 0 100%);
  height: 100%;
  padding: 0px 25px 0px 0px;
  ${props => props.theme.breakpoints.up('res1024')} {
    width: 250px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    width: 180px;
  }
`

const ContentContainer = styled(Box)`
  width: 0px; // need a width with any value to make flex-grow work
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  padding: 0px 16px 0px 16px;
  height: 100%;

  ${props => props.theme.breakpoints.up('res1024')} {
    padding: 0px 32px 0px 32px;
  }
`

const PrimaryBar: FC<Props> = props => {
  const { children, className, logoUrl = ROUTE.HOME } = props

  return (
    <StyledAppBar className={className}>
      <StyledContainer className='Custom-Container'>
        <StyledToolbar className='Custom-Toolbar'>
          <LogoContainer>
            <NextLink href={logoUrl}>
              <Logo src={LogoJPG} />
            </NextLink>
          </LogoContainer>
          <ContentContainer>{children}</ContentContainer>
        </StyledToolbar>
      </StyledContainer>
    </StyledAppBar>
  )
}

type Props = {
  children?: ReactNode
  className?: string
  logoUrl?: string
}

export default PrimaryBar
