import {
  CurrencyTokenType,
  currencyTokenTypeSchema,
  MayNull
} from '@apeiron/library'
import {
  ConjunctCostMap,
  ConjunctCostSingleMap,
  ConjunctItem
} from '@src/types/conjunct'
import * as R from 'ramda'
import * as yup from 'yup'

const costArraySchema: yup.SchemaOf<ConjunctCostSingleMap> = yup
  .array()
  .of(yup.number())
  .default([])

export const conjunctCostMapSchema: yup.SchemaOf<ConjunctCostMap> = yup
  .object({
    backgroundColor: yup.string().default('#d3d3d3'),
    cost: yup.lazy<yup.SchemaOf<ConjunctCostSingleMap>>((value: any): any => {
      const keys = R.keys(value) as CurrencyTokenType[]

      const fields = R.reduce(
        (
          acc: { CurrencyTokenType?: yup.SchemaOf<number[]> },
          key: CurrencyTokenType
        ) => {
          return R.assoc(key, costArraySchema, acc)
        },
        {},
        keys
      )

      return yup.object(fields).required().noUnknown(true)
    }),
    name: yup.string().default(''),
    type: yup.string().default('')
  })
  .camelCase()

const conjunctItemFromPoolSchema: yup.SchemaOf<ConjunctItem> = yup
  .object({
    conjunctButtonText: yup.string().default(''),
    addressConfig: yup.string().default(''),
    bloodline: yup.boolean().default(false),
    image: yup.string().default(''),
    imageLite: yup.string().default(''),
    name: yup.string().default(''),
    type: currencyTokenTypeSchema,
    erc20: yup.boolean().default(false),
    cost: yup.object({
      bloodline: yup.boolean().default(false),
      firstGeneration: yup.boolean().default(false)
    }),
    discountedBy: yup
      .object({
        image: yup.string().default(''),
        imageLite: yup.string().default(''),
        name: yup.string().default(''),
        type: currencyTokenTypeSchema.optional()
      })
      .optional()
  })
  .from('metaKey.conjunctButtonText', 'conjunctButtonText')
  .from('metaKey.addressConfig', 'addressConfig')
  .from('metaKey.bloodline', 'bloodline')
  .from('metaKey.image', 'image')
  .from('metaKey.imageLite', 'imageLite')
  .from('metaKey.name', 'name')
  .from('metaKey.type', 'type')
  .from('metaKey.erc20', 'erc20')
  .from('metaKey.cost', 'cost')
  .noUnknown(true)

export const conjunctItemSchema: yup.SchemaOf<
  any,
  Record<any, MayNull<ConjunctItem>>
> = yup
  .object(
    Object.values(CurrencyTokenType).reduce((accumulate, key) => {
      return {
        ...accumulate,
        [key]: yup.lazy(value =>
          value ? conjunctItemFromPoolSchema : yup.mixed().notRequired()
        )
      }
    }, {})
  )
  .transform(value => {
    return R.prop('meta_key', value)
  })
