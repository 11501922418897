import usePath from '@src/hooks/common/router/usePath'
import { NextSeo } from 'next-seo'
import { FC } from 'react'

const OgDataNoIndex: FC<Props> = () => {
  const { noIndex } = usePath()

  return noIndex ? <NextSeo noindex /> : <></>
}

type Props = {}

export default OgDataNoIndex
