// import ENV_CONFIG from '@src/config'
import { createStrictContext } from '@apeiron/library'
import {
  differenceInSeconds,
  isAfter as dateIsAfter,
  isBefore as dateIsBefore
} from 'date-fns'
import * as R from 'ramda'
import { ReactChild, useCallback, useEffect, useState } from 'react'

const SERVER_TIME_REFRESH_INTERVAL = 1000 * 60 * 30

const [ContextProvider, useNowContext] = createStrictContext<ContextType>('Now')

export { useNowContext }

export const NowProvider = (props: Props) => {
  const { children } = props

  const [serverNow, setServerNow] = useState<Date | null>(null)

  const fetchTime = useCallback(async () => {
    // TODO - cannot fetch due to CORS problem
    // const headers = new Headers()

    // headers.append('content-type', 'application/json')
    // headers.append('cache-control', 'no-cache')

    // const response = await fetch(ENV_CONFIG.REST_API_URL.MARKETPLACE, {
    //   method: 'GET',
    //   headers
    // })

    // if (response.ok) {
    //   setServerNow(null)
    // }
    setServerNow(null)
  }, [])

  const now = useCallback((): Date => {
    const now = new Date()

    return !R.isNil(serverNow) &&
      Math.abs(differenceInSeconds(now, serverNow)) >
        SERVER_TIME_REFRESH_INTERVAL
      ? serverNow
      : now
  }, [serverNow])

  const isAfterNow = useCallback(
    (date: Date): boolean => dateIsAfter(date, now()),
    [now]
  )

  const isBeforeNow = useCallback(
    (date: Date): boolean => dateIsBefore(date, now()),
    [now]
  )

  useEffect(() => {
    fetchTime()

    const timer = setInterval(() => {
      fetchTime()
    }, SERVER_TIME_REFRESH_INTERVAL)

    return () => clearInterval(timer)
  }, [fetchTime])

  return (
    <ContextProvider value={{ now, isAfterNow, isBeforeNow }}>
      {children}
    </ContextProvider>
  )
}

type ContextType = {
  now: () => Date
  isAfterNow: (date: Date) => boolean
  isBeforeNow: (date: Date) => boolean
}

type Props = {
  children: ReactChild | ReactChild[]
}
