import * as R from 'ramda'
import { useMemo } from 'react'

const useUserAgent = (): Hook => {
  const userAgent =
    typeof window !== 'undefined'
      ? R.pathOr('', ['navigator', 'userAgent'], window)
      : ''

  const isWhatsApp = useMemo(() => {
    return R.test(/whatsapp/i, userAgent)
  }, [userAgent])

  return {
    userAgent,
    isWhatsApp
  }
}

type Hook = {
  userAgent: string
  isWhatsApp: boolean
}

export default useUserAgent
