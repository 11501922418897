import { deserializeData, useAccountContext } from '@apeiron/library'
import { useQuery } from '@apollo/client'
import { NftType } from '@src/constants/bookmark'
import { starSchema } from '@src/deserialize/yup/star'
import graphql from '@src/graphql/query/getStarDetail'
import useGetGodiverses from '@src/hooks/graphql/useGetGodiverses'
import useGetStarBookmarkStatus from '@src/hooks/graphql/useGetNFTBookmarkStatus'
import { GodiverseLite } from '@src/types/godiverse'
import { OrbitalSlot, Star, StarSlotsMap } from '@src/types/star'
import * as R from 'ramda'
import * as RA from 'ramda-adjunct'
import { useCallback, useEffect, useState } from 'react'

const useGetStar = (starID: string): Hook => {
  const {
    data: apolloData,
    loading,
    refetch: apolloRefetch
  } = useQuery(graphql, {
    variables: { starID },
    skip: RA.isNilOrEmpty(starID)
  })

  const { isLoggedIn } = useAccountContext()

  const { fetch: getAstronomicals } = useGetGodiverses()

  const { fetch: getBookmarkStatus } = useGetStarBookmarkStatus()

  const [data, setData] = useState<Star | null>(null)

  const getSlotsInfo = useCallback(
    async (star: Star): Promise<StarSlotsMap> => {
      const extractAstroIds = R.pipe<
        any,
        StarSlotsMap,
        Array<OrbitalSlot>,
        Array<Array<GodiverseLite | null>>,
        Array<GodiverseLite | null>,
        Array<GodiverseLite>,
        Array<string>
      >(
        R.propOr({}, 'slotsMap'),
        R.values,
        items => R.pluck('equipped', items),
        R.flatten,
        R.reject(R.isNil),
        items => R.pluck('id', items)
      )

      const astronomicalIds = extractAstroIds(star)

      const { data: fetchedAstronomicals } = await getAstronomicals({
        filterInput: { tokenIDs: astronomicalIds }
      })

      const slotsMap = R.reject(R.isNil, star.slotsMap) // in case slots cannot be mapped

      return R.mapObjIndexed((slot: OrbitalSlot) => {
        const { equipped } = slot

        const filledItems = R.map((astronomical: GodiverseLite | null) => {
          return R.find(
            (fetched: GodiverseLite) => fetched.id === astronomical?.id,
            fetchedAstronomicals
          )
        }, equipped)

        return R.assoc('equipped', filledItems, slot)
      }, slotsMap) as unknown as StarSlotsMap
    },
    [getAstronomicals]
  )

  const setAndSerializeReponse = useCallback(
    async (response: any) => {
      if (response.data) {
        let star = deserializeData<Star>(response, starSchema)

        if (star) {
          setData(star)

          const slotsMap = await getSlotsInfo(star)

          star = {
            ...star,
            slotsMap
          }

          setData(star)

          if (isLoggedIn) {
            const isBookmarked = await getBookmarkStatus({
              nftType: NftType.Star,
              tokenId: star.id
            })

            star = {
              ...star,
              isBookmarked
            }

            setData({
              ...star,
              isBookmarked
            })
          }
        }
      } else {
        setData(null)
      }
    },
    [getSlotsInfo, getBookmarkStatus, isLoggedIn]
  )

  const refetch = useCallback(async () => {
    const response = await apolloRefetch()

    setAndSerializeReponse(response)
  }, [apolloRefetch, setAndSerializeReponse])

  useEffect(() => {
    setAndSerializeReponse({ data: apolloData })
  }, [apolloData])

  return {
    data,
    loading,
    refetch
  }
}

type Response = Star | null

type Hook = {
  data: Response
  loading: boolean
  refetch: () => Promise<void>
}

export default useGetStar
