import { i18nNamespace, initI18N } from '@apeiron/library'
import ENV_CONFIG from '@src/config'
import buildId from '@src/constants/buildId'
import i18n from 'i18next'

initI18N({
  buildId,
  defaultNS: i18nNamespace.Marketplace,
  domain: ENV_CONFIG.DOMAIN_URL.JSON,
  env: ENV_CONFIG.VARIABLES.ENV,
  fallbackNS: i18nNamespace.Share,
  ns: [i18nNamespace.Marketplace, i18nNamespace.Share]
})

export default i18n
