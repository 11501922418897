import { AnyObject } from '@apeiron/library'
import { useLazyQuery } from '@apollo/client'
import { NotificationDataType } from '@src/constants/notification'
import useMakeNotification from '@src/deserialize/hook/useMakeNotification'
import useMakeResult from '@src/deserialize/hook/useMakeResult'
import graphql from '@src/graphql/query/getMyNotifications'
import { NotificationData } from '@src/types/notification'
import { useCallback } from 'react'

const useGetMyNotifications = (): Hook => {
  const [fetchNotifications, { loading }] = useLazyQuery(graphql)

  const { makeResult } = useMakeResult()

  const { makeNotifications } = useMakeNotification()

  const fetch = useCallback(
    async (request: Request): Promise<Response> => {
      const response = await fetchNotifications({
        variables: {
          input: request
        }
      })

      const result = makeResult(response)

      const notifications = makeNotifications(result as AnyObject)

      return notifications
    },
    [fetchNotifications, makeNotifications, makeResult]
  )

  return {
    loading,
    fetch
  }
}

type Request = {
  notificationTypes: NotificationDataType[]
}

type Response = NotificationData[]

type Hook = {
  loading: boolean
  fetch: (request: Request) => Promise<Response>
}

export default useGetMyNotifications
