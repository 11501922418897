import { DEFAULT_ANIMATE_DURATION } from '@apeiron/library'
import { Button, ButtonProps, styled } from '@mui/material'
import { forwardRef, Ref } from 'react'

const StyledButton = styled(Button)`
  background-color: #27282a;
  border-radius: 4px;
  padding: 15px 25px;
  transition: background-color ${DEFAULT_ANIMATE_DURATION}ms,
    filter ${DEFAULT_ANIMATE_DURATION}ms;
  font-weight: 300;
  font-size: 16px;
  color: white;
  text-transform: none;
  &:hover {
    background-color: #1d66bf;
    filter: brightness(1.2);
  }
  ${props => props.theme.breakpoints.up('res1024')} {
    min-width: 160px;
  }
`

const ApeironSimpleButton = forwardRef(function SimpleButton(
  props: ButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  return <StyledButton {...props} ref={ref} />
})

export default ApeironSimpleButton
