import { gql } from '@apollo/client'
import { OwnerFragment } from '@src/graphql/fragment/account'
import { ListingInfoFragment } from '@src/graphql/fragment/listingInfo'

export const StarSuperLiteFragment = gql`
  fragment StarSuperLite on Star {
    additionAgingBuffValue
    additionOrbitalTrackValue
    ageBuffValue
    nftListingStatus
    starID
    starName
    tier
    trackCountValue
    owner {
      ...Owner
    }
  }

  ${OwnerFragment}
`

export const StarLiteFragment = gql`
  fragment StarLite on Star {
    ...StarSuperLite
    constellation
    constellationDigit
    constellationType
    galaxy
    galaxyImage
    starImage
    status
    listingInfo {
      ...ListingInfo
    }
  }

  ${StarSuperLiteFragment}
  ${ListingInfoFragment}
`

export const StarFragment = gql`
  fragment Star on Star {
    ...StarLite
    additionOrbitalTrackValue
    celestialObject
    galaxy
    permanentSupernova
    priceInUSD
    slotItems
    slots
    starPositionImage
    trackCountValue
  }
  ${StarLiteFragment}
`

export const StarForVerifyFragment = gql`
  fragment StarForVerify on Star {
    listingInfo {
      ...ListingInfo # for update star price
    }
    nftListingStatus # for sell star
    owner {
      walletAddress # for buy / gift star
    }
    slotItems # for star attach godiverse
    slots # for star attach godiverse
    starID
  }
  ${ListingInfoFragment}
`
