import { CurrencyTokenType } from '@apeiron/library'

// enum - contract operate status (used on ui)
export enum ContractWorkStatus {
  IDLE = 0,
  OPERATING,
  SUCCESS,
  FAIL
}

export enum ContractEventType {
  TRANSACTION_HASH = 'TxnHash',
  REQUEST_ID = 'RequestID'
}

export const ContractEventParams = {
  OrbitOwnerAttach: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'StarOwnerAttachOwnPlanetSummary',
    eventName: 'StarOwnerAttachOwnPlanetSummary'
  },
  OrbitOwnerDetach: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'StarOwnerDetachPlanetSummary',
    eventName: 'StarOwnerDetachPlanetSummary'
  },
  OrbitRenterAttach: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'RentingOrbitalTrackSummary',
    eventName: 'RentingOrbitalTrackSummary'
  },
  OrbitRenterDetach: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'PlanetOwnerDetachPlanetSummary',
    eventName: 'PlanetOwnerDetachPlanetSummary'
  },
  OrbitRenterRenew: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'RenewRentingOrbitalTrackSummary',
    eventName: 'RenewRentingOrbitalTrackSummary'
  },
  SeasonApostleMint: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'SeasonApostleMint',
    eventName: 'SeasonMintSummary'
  },
  TicketApostleMint: {
    type: ContractEventType.REQUEST_ID,
    name: 'RequestTicketMinting',
    eventName: 'RequestTicketMinting'
  },
  OrbitalTracksUpdated: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'OrbitalTracksUpdated',
    eventName: 'OrbitalTracksUpdated'
  },
  Vesting: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'VestingReleased',
    eventName: 'VestingReleased'
  },
  DailyCheckIn: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'DailyCheckin',
    eventName: 'DailyCheckin'
  },
  OrderCancelled: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'OrderCancelled',
    eventName: 'OrderCancelled'
  },
  OrderMatched: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'OrderMatched',
    eventName: 'OrderMatched'
  },
  MakeupCheckin: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'MakeupCheckin',
    eventName: 'MakeupCheckin'
  },
  ManagePlanetApostle: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'ManageRelicApostlesSummary',
    eventName: 'ManageRelicApostlesSummary'
  },
  CreateBounty: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'CreateBounty',
    eventName: 'CreateBounty'
  },
  CancelBounty: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'CancelBounty',
    eventName: 'CancelBounty'
  },
  FulfillBounty: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'FulfillBounty',
    eventName: 'FulfillBounty'
  },
  ApostleForgeSummary: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'ApostleForgeSummary',
    eventName: 'ApostleForgeSummary'
  },
  StakedToken: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'StakedToken',
    eventName: 'StakedToken'
  },
  UnstakedToken: {
    type: ContractEventType.TRANSACTION_HASH,
    name: 'UnstakedToken',
    eventName: 'UnstakedToken'
  }
}

// const - allowance
export const DEFAULT_REQUEST_ALLOWANCE = {
  [CurrencyTokenType.Weth]: 10000000,
  [CurrencyTokenType.Usdc]: 10000,
  [CurrencyTokenType.Anima]: 10000000,
  [CurrencyTokenType.Aprs]: 10000000
}

// const - gas fee
export const GAS_FEE_PRIORITY = 50000000000 // gas fee (priority) - max (50 gwei)

// const- wait backend update data for 2s, then refetch
export const DELAY_REFETCH_AFTER_CONTRACT_SUCCESS = 5000

export const RONIN_GAS_LIMIT = {
  APOSTLE_TICKET_MINT: '1000000',
  BREED: '1500000',
  BUY_ORDER: '800000',
  MORPH: '400000',
  VRF: '200000000000000000'
}

export const RONIN_VRF_ESIMATE_MINUTES = 2

export const RONIN_VRF_ESIMATE_FEE = 0.2
