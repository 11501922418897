import { PublicInvitationFragment } from '@apeiron/library'
import { gql } from '@apollo/client'
import { PublicOwnerFragment } from '@src/graphql/fragment/account'
import { PageInfoFragment } from '@src/graphql/fragment/pageInfo'

export default gql`
  query GetAccountInvitees($input: GetInviteesFilterInput) {
    getAccountInvitees(input: $input) {
      edges {
        account {
          ...PublicOwner
        }
        accountInvitation {
          ...PublicInvitation
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PublicOwnerFragment}
  ${PublicInvitationFragment}
  ${PageInfoFragment}
`
