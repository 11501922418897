import { GodiverseMetadataType, GodiverseType } from '@src/constants/godiverse'
import { OrbitalSlotType } from '@src/constants/star'
import { listingInfoSchema } from '@src/deserialize/yup/listingInfo'
import {
  AddOn,
  Godiverse,
  GodiverseAttachRequirement,
  GodiverseBalanceInfo,
  GodiverseLite,
  GodiverseMetadata,
  GodiverseMetadataAstronomical,
  GodiverseMetadataBreedingRelic,
  GodiverseMetadataItem,
  GodiverseTier,
  GodiverseTypeInfo
} from '@src/types/godiverse'
import * as R from 'ramda'
import * as yup from 'yup'

const godiverseTypeSchema: yup.BaseSchema<GodiverseType> = yup
  .mixed()
  .oneOf(Object.values(GodiverseType))

const orbitalSlotTypeSchema: yup.BaseSchema<OrbitalSlotType> = yup
  .mixed()
  .oneOf(Object.values(OrbitalSlotType))

const godiverseMetadataType: yup.BaseSchema<GodiverseMetadataType> = yup
  .mixed()
  .oneOf(Object.values(GodiverseMetadataType))
  .default(GodiverseMetadataType.Item)

const godiverseBalanceInfoSchema: yup.SchemaOf<GodiverseBalanceInfo> =
  yup.object({
    balance: yup.number().default(0),
    listingCount: yup.number().default(0),
    stakeCount: yup.number().default(0)
  })

export const godiverseTierSchema: yup.SchemaOf<GodiverseTier> = yup.object({
  color: yup.string().default(''),
  level: yup.number().default(0),
  name: yup.string().default('')
})

export const godiverseTypeInfoSchema: yup.SchemaOf<GodiverseTypeInfo> = yup
  .object({
    color: yup.string().default('unset'),
    groupIndex: yup.number().default(0),
    groupName: yup.string().default(''),
    name: yup.string().default(''),
    type: godiverseTypeSchema.default(GodiverseType.MiniBlackHole)
  })
  .from('id', 'type')
  .camelCase()

const godiverseAttachLimitSchema: yup.SchemaOf<GodiverseAttachRequirement> = yup
  .object({
    starTier: yup.number().default(0),
    slotTypes: yup.array().of(orbitalSlotTypeSchema).default([])
  })
  .when(['$data'], (data: Record<string, any>) => {
    const slots = R.pathOr([], ['godiverseMeta', 'canAttachToSlots'], data)

    const result = R.isEmpty(slots)
      ? undefined
      : {
          starTier: R.pathOr(0, ['godiverseMeta', 'minTierRequired'], data),
          slotTypes: slots
        }

    return yup.object().default(result)
  })

const godiverseMetadataAstronomicalSchema: yup.SchemaOf<GodiverseMetadataAstronomical> =
  yup.object({
    dataType: godiverseMetadataType
  })

const godiverseMetadataBreedingRelicSchema: yup.SchemaOf<GodiverseMetadataBreedingRelic> =
  yup
    .object({
      dataType: godiverseMetadataType,
      effects: yup.string().default(''),
      planetSupported: yup.string().default(''),
      rarity: yup.string().default('')
    })
    .from('relicDescription', 'effects')
    .from('equipPlanettypeConditionText', 'planetSupported')
    .noUnknown(true)

const godiverseMetadataItemSchema: yup.SchemaOf<GodiverseMetadataItem> =
  yup.object({
    dataType: godiverseMetadataType
  })

const godiverseMetadataSchema: yup.SchemaOf<GodiverseMetadata> = yup
  .object({
    dataType: godiverseMetadataType
  })
  .when(['$data'], (data: Record<string, any>) => {
    const type = R.pathOr(
      GodiverseMetadataType.Item,
      ['metaData', 'dataType'],
      data
    ) as GodiverseMetadataType

    switch (type) {
      case GodiverseMetadataType.Astronomical:
        return godiverseMetadataAstronomicalSchema

      case GodiverseMetadataType.BreedingRelic:
        return godiverseMetadataBreedingRelicSchema

      case GodiverseMetadataType.Item:
      default:
        return godiverseMetadataItemSchema
    }
  })

const addOnSchema: yup.SchemaOf<AddOn> = yup.object({
  disabled: yup.object({
    message: yup.string().default(''),
    value: yup.boolean().default(false)
  }),
  selected: yup.number().default(0)
})

const godiverseShareSchema: yup.SchemaOf<GodiverseLite> = yup
  .object({
    agingBuff: yup.number().default(0),
    attachLimit: godiverseAttachLimitSchema.nullable().default(undefined),
    balanceInfo: godiverseBalanceInfoSchema.nullable().default(null),
    cheapestListingInfo: listingInfoSchema.nullable().default(null),
    id: yup.string().default(''),
    image: yup.string().default(''),
    listingInfo: listingInfoSchema.nullable().default(null),
    metadata: godiverseMetadataSchema,
    name: yup.string().default(''),
    orbitTrack: yup.number().default(0),
    tier: godiverseTierSchema.nullable().default(null),
    typeInfo: godiverseTypeInfoSchema.nullable().default(null)
  })
  .concat(addOnSchema)
  .from('godiverseMeta.agingBuffValue', 'agingBuff')
  .from('godiverseMeta.image', 'image')
  .from('godiverseMeta.itemID', 'id')
  .from('godiverseMeta.itemType', 'typeInfo')
  .from('godiverseMeta.orbitTrack', 'orbitTrack')
  .from('godiverseMeta.tier', 'tier')
  .from('metaData.name', 'name')
  .from('metaData', 'metadata')
  .from('minListingInfo', 'cheapestListingInfo')
  .from('ownerBalanceInfo', 'balanceInfo')

export const godiverseLiteSchema: yup.SchemaOf<GodiverseLite> =
  godiverseShareSchema.noUnknown(true)

export const godiverseSchema: yup.SchemaOf<Godiverse> = godiverseShareSchema
  .concat(
    yup.object({
      isBookmarked: yup.boolean().default(false),
      totalCount: yup.number().default(0)
    })
  )
  .from('godiverseMeta.totalCount', 'totalCount')
  .noUnknown(true)
