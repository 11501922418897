import { ChainAddressType, useChainSwitcher } from '@apeiron/library'
import { JsonRpcProvider } from '@ethersproject/providers'
import { RNS } from '@roninnetwork/rnsjs'
import ENV_CONFIG from '@src/config'
import { useRoninNameServiceContext } from '@src/contexts/contract/RoninNameServiceContext'
import * as R from 'ramda'
import { useCallback } from 'react'

const useRoninNameService = (): Hook => {
  const { getChainIdByType } = useChainSwitcher()

  const { rns } = useRoninNameServiceContext()

  const init = useCallback(async () => {
    if (R.isNil(rns.current)) {
      rns.current = new RNS()

      const chainId = getChainIdByType(ChainAddressType.RONIN)

      const provider = new JsonRpcProvider(
        ENV_CONFIG.THIRD_PARTY_API_URL.SKY_MAVIS_RPC
      )

      await rns.current.setProvider(provider, chainId)
    }
  }, [getChainIdByType, rns])

  const maybeRNS = useCallback((name: string): boolean => {
    return R.endsWith('.ron', name)
  }, [])

  const resolveName = useCallback(
    async (name: string) => {
      await init()

      if (R.isNotNil(rns.current)) {
        const address = await rns.current.getAddr(name)

        return address
      }

      return ''
    },
    [init, rns]
  )

  return {
    maybeRNS,
    resolveName
  }
}

type Hook = {
  maybeRNS: (name: string) => boolean
  resolveName: (name: string) => Promise<string>
}

export default useRoninNameService
