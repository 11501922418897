import { AnyObject, MayNull } from '@apeiron/library'
import { FetchResult } from '@apollo/client'
import * as R from 'ramda'
import { useCallback } from 'react'

const useMakeResult = (): Hook => {
  /** @public */
  const makeResult = useCallback(
    <T extends unknown>(response: FetchResult<AnyObject>): MayNull<T> => {
      const data: FetchResult<AnyObject> = R.propOr({}, 'data', response)

      const operation = R.propOr<'', string[], string>('', '0', R.keys(data))

      return R.pathOr<MayNull<T>>(null, ['data', operation], response)
    },
    []
  )

  return {
    makeResult
  }
}

type Hook = {
  makeResult: <T extends unknown>(
    response: FetchResult<AnyObject>
  ) => MayNull<T>
}

export default useMakeResult
