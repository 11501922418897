import {
  ApeironLinkButton,
  DEFAULT_ANIMATE_DURATION,
  NextImage
} from '@apeiron/library'
import { styled } from '@mui/material'
import InventoryPNG from '@public/icons/icon-inventory.png'
import { ROUTE } from '@src/constants/route'
import { FC } from 'react'

const Button = styled(ApeironLinkButton)`
  border-radius: unset;
  height: 100%;
  padding: 0px 6px;
  width: 44px;
  :hover {
    background-color: #555c7e;
    transition: ${DEFAULT_ANIMATE_DURATION}ms;
  }
`

const InventoryIcon = styled(NextImage)`
  aspect-ratio: 1;
  width: 100%;
`

const ShortcutInventoryButton: FC<Props> = (props: Props) => {
  const { className } = props

  return (
    <Button className={className} url={ROUTE.INVENTORY_PLANET_LIST}>
      <InventoryIcon src={InventoryPNG} />
    </Button>
  )
}

type Props = {
  className?: string
}

export default ShortcutInventoryButton
