import { createStrictContext, MayNull } from '@apeiron/library'
import { RNS } from '@roninnetwork/rnsjs'
import { MutableRefObject, ReactNode, useRef } from 'react'

type IRoninNameServiceContext = {
  rns: MutableRefObject<MayNull<RNS>>
}

const [ContextProvider, useRoninNameServiceContext] =
  createStrictContext<IRoninNameServiceContext>('RoninNameService')

export { useRoninNameServiceContext }

export const RoninNameServiceProvider = (props: Props) => {
  const { children } = props

  const rns = useRef<MayNull<RNS>>(null)

  return <ContextProvider value={{ rns }}>{children}</ContextProvider>
}

type Props = {
  children: ReactNode
}
