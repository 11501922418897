/** type */
import { CurrencyTokenType } from '@apeiron/library'

type GiftCodRewardMetaData = {
  name: string
  image?: string
  format?: 'datetime'
  type?: CurrencyTokenType
}

/** @deprecated - will be moved to web-store */
export type GiftCodeClaimRecord = {
  code: string
  redeemTime: string
  rewards: GiftCodeReward[]
}

/** @deprecated - will be moved to web-store */
export type GiftCodeClaimHistory = GiftCodeClaimRecord[]

/** @deprecated - will be moved to web-store */
export type GiftCodeReward = {
  rewardType: GiftCodeRewardType
  rewardID: string
  quantity: number
  decimal: number
  metaData: GiftCodRewardMetaData
}

/** constant */
/** @deprecated - will be moved to web-store */
export enum GiftCodeRewardType {
  ClaimableReward = 'ClaimableReward',
  HostedWalletReward = 'HostedWalletReward',
  GameServerReward = 'GameServerReward'
}

/** @deprecated - will be moved to web-store */
export const GIFT_CODE_ROUTE = '/user/reward/redeem/'
