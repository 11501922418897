import { MayNull } from '@apeiron/library'
import useRoninNameService from '@src/hooks/contract/useRoninNameService'
import * as R from 'ramda'
import { useCallback } from 'react'

const ETH_ADDRESS_PREFIX = '0x'

const RONIN_ADDRESS_PREFIX = 'ronin:'

const useWalletAddress = (): Hook => {
  const { maybeRNS } = useRoninNameService()

  const compareAddress = useCallback(
    (addr1: MayNull<string>, addr2: MayNull<string>): boolean => {
      return addr1 && addr2
        ? addr1.toLowerCase() === addr2.toLowerCase()
        : false
    },
    []
  )

  const maybeAddress = useCallback(
    (input: MayNull<string>): boolean => {
      const matchPattern = (input: string): boolean =>
        R.startsWith(ETH_ADDRESS_PREFIX, input) ||
        R.startsWith(RONIN_ADDRESS_PREFIX, input)

      return R.isNotNil(input) && !maybeRNS(input) && matchPattern(input)
    },
    [maybeRNS]
  )

  const toEthAddress = useCallback((address: MayNull<string>): string => {
    if (R.isNil(address)) return ''

    const transform = R.pipe(
      R.replace(RONIN_ADDRESS_PREFIX, ETH_ADDRESS_PREFIX) // support ronin address (add more below)
    )

    return transform(address)
  }, [])

  return {
    compareAddress,
    maybeAddress,
    toEthAddress
  }
}

type Hook = {
  compareAddress: (addr1: MayNull<string>, addr2: MayNull<string>) => boolean
  maybeAddress: (input: string) => boolean
  toEthAddress: (address: MayNull<string>) => string
}

export default useWalletAddress
