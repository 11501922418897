import Head from 'next/head'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const NextHead: FC<Props> = () => {
  const { t } = useTranslation()

  return (
    <Head>
      <title>{t('head.title')}</title>
      <link rel='icon' type='image/png' href='/favicon.png' />
      <meta name='viewport' content='width=device-width,initial-scale=1' />
    </Head>
  )
}

type Props = {}

export default NextHead
