// ? align with backend api
export enum ListingStatus {
  LISTING = 'Listing',
  UNLISTING = 'UnListing'
}

// ? align with backend api
export enum RoninOrderStatus {
  Opened = 'Opened',
  Matched = 'Matched',
  Expired = 'Expired',
  OffererCancel = 'OffererCancel'
}
