import { Box, styled } from '@mui/material'
import { NAV_BAR_CONFIG } from '@src/constants/layout'
import { useSecondaryNavBarContext } from '@src/contexts/share/SecondaryNavBarContext'
import { FC } from 'react'

const Container = styled(Box)`
  border-bottom: ${NAV_BAR_CONFIG.secondary.borderWidth}px solid #151615;
  position: sticky;
  top: ${NAV_BAR_CONFIG.primary.height}px;
  width: 100%;
  z-index: 1; // set to hide content below navigation bar
`

const SecondaryNavBar: FC<Props> = () => {
  const { bar } = useSecondaryNavBarContext()

  return bar ? <Container>{bar}</Container> : <></>
}

type Props = {}

export default SecondaryNavBar
