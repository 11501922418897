import {
  CurrencyToken,
  noForwardCustomProps,
  useCurrencyContext
} from '@apeiron/library'
import { Box, styled, Typography, useMediaQuery } from '@mui/material'
import Token from '@src/components/account/header/Token'
import theme from '@src/styles/theme'
import * as R from 'ramda'
import { FC } from 'react'

const Container = styled(Box)`
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 12px;
  background: var(--inventory-buttons, #31333f);
`

const Title = styled(Typography, noForwardCustomProps(['color']))<TitleProps>`
  color: ${props => props.color || 'white'};
  font-weight: 700;
`

const TokensContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
  .Custom-Token:last-child {
    border-right: unset;
    padding-right: unset;
  }
`

const TokenContainer = styled(Box)`
  border-right: 1px solid #7d7e7f;
  padding-right: 16px;
`

const MobileTokensContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, minmax(50px, 1fr));
  grid-row-gap: 16px;
  .Custom-Token:last-child {
    border-right: unset;
    padding-right: unset;
  }
  .Custom-Token:nth-of-type(even) {
    border-right: unset;
    padding-right: unset;
    padding-left: 26px;
  }
`

const MobileTokenContainer = TokenContainer

const MobileToken = styled(Token)`
  max-width: 120px;
`

const TokenSection: FC<Props> = props => {
  const { className, title, tokens } = props

  const { getApeironPrice } = useCurrencyContext()

  const isMobile = useMediaQuery(theme.breakpoints.down('res1280'))

  const GenericTokensContainer = isMobile
    ? MobileTokensContainer
    : TokensContainer

  const GenericTokenContainer = isMobile ? MobileTokenContainer : TokenContainer

  const GenericToken = isMobile ? MobileToken : Token

  return !R.isEmpty(tokens) ? (
    <Container className={className}>
      <Title className='Custom-Title'>{title}</Title>
      <GenericTokensContainer>
        {R.map((token: CurrencyToken) => {
          return (
            <GenericTokenContainer className='Custom-Token' key={token.name}>
              <GenericToken
                amount={getApeironPrice(token.type, token.amount, {
                  maxDecimal: 4,
                  maxLength: 6
                })}
                iconUrl={token.image}
                unit={token.name}
              />
            </GenericTokenContainer>
          )
        }, tokens)}
      </GenericTokensContainer>
    </Container>
  ) : (
    <></>
  )
}

type TitleProps = {
  color?: string
}

type Props = {
  className?: string
  title: string
  tokens: CurrencyToken[]
}

export default TokenSection
