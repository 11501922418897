import { useAccountContext } from '@apeiron/library'
import { Box, styled } from '@mui/material'
import ApeironIcon from '@public/icons/icon-qr-logo.png'
import Image from 'next/image'
import { QRCodeSVG } from 'qrcode.react'
import React from 'react'

const QRCodeContainer = styled('div')`
  width: 264px;
  height: 264px;
  aspect-ratio: 1;
  color: white;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const QRCodeIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 8;
`

const QRCodeApeironIcon = styled(Image)`
  margin: auto auto auto auto;
`

type Props = {}

const QRCode: React.FC<Props> = () => {
  const { account } = useAccountContext()

  return (
    <QRCodeContainer>
      <QRCodeIconContainer>
        <QRCodeApeironIcon
          src={ApeironIcon}
          alt={'Apeiron Icon'}
          width={82}
          height={91}
          layout='intrinsic'
          loader={({ src }) => src}
        />
      </QRCodeIconContainer>
      <QRCodeSVG size={300} level='L' value={account.accessToken} />
    </QRCodeContainer>
  )
}

export default QRCode
