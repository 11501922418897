import 'swiper/css/effect-fade'

import {
  INFO_POPUP_TYPE,
  InfoPopup,
  isIosOrAndroid,
  useAccountContext
} from '@apeiron/library'
import { styled, Typography } from '@mui/material'
import OkxWalletButton from '@src/components/wallet/buttons/OkxWalletButton'
import RoninWalletButton from '@src/components/wallet/buttons/RoninWalletButton'
import ENV_CONFIG from '@src/config'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Header = styled(Typography)`
  text-align: center;
`

const StyledInfoPopup = styled(InfoPopup)`
  background-color: #262829;
  border-radius: 6px;
  height: unset;
  max-height: 95%;
  max-width: 95%;
  min-width: 360px;
  width: unset;

  .Custom-Content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    overflow-y: auto;
    padding: 0px 50px 40px 50px;
    width: 100%;

    ${props => props.theme.breakpoints.down('res768')} {
      padding: 0px 20px 20px 20px;
    }
  }
`

const MissWalletPopup: FC<Props> = () => {
  const {
    missWallet,
    warnNetworkOnly,
    wrongNetwork,
    setMissWallet,
    setWarnNetworkOnly,
    setWrongNetwork
  } = useAccountContext()

  const { t } = useTranslation()

  const [showPopup, setShowPopup] = useState(false)

  const handleOnClose = useCallback(() => {
    setShowPopup(false)
    setTimeout(() => {
      setMissWallet(null)
      setWrongNetwork(null)
      setWarnNetworkOnly(false)
    }, 500)
  }, [setMissWallet, setWarnNetworkOnly, setWrongNetwork])

  useEffect(() => {
    setShowPopup(!!missWallet)
  }, [missWallet, warnNetworkOnly, wrongNetwork])

  const handleRoninClick = useCallback(() => {
    if (isIosOrAndroid()) {
      location.href =
        'roninwallet://in_app_browser?url=' + encodeURIComponent(location.href)
    } else {
      window.open(ENV_CONFIG.EXTERNAL_URL.WALLET.RONIN.DOWNLOAD)
    }
  }, [])

  const handleOkxClick = useCallback(() => {
    if (isIosOrAndroid()) {
      const encodedUrl =
        'https://www.okx.com/download?deeplink=' +
        encodeURIComponent(
          'okx://wallet/dapp/url?dappUrl=' + encodeURIComponent(location.href)
        )

      window.location.href = encodedUrl
    } else {
      window.open(ENV_CONFIG.EXTERNAL_URL.WALLET.OKX.DOWNLOAD)
    }
  }, [])

  return (
    <StyledInfoPopup
      open={showPopup}
      type={INFO_POPUP_TYPE.BLACK}
      onClose={handleOnClose}
    >
      <Header>
        {t('wallet.miss_wallet_header', {
          context: isIosOrAndroid() ? 'mobile' : 'desktop'
        })}
      </Header>
      <RoninWalletButton onClick={handleRoninClick} />
      <OkxWalletButton onClick={handleOkxClick} />
    </StyledInfoPopup>
  )
}

type Props = {}

export default MissWalletPopup
