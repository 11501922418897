import { DEFAULT_ANIMATE_DURATION, NextImage } from '@apeiron/library'
import { Box, Divider, styled, Typography } from '@mui/material'
import ApeironLoadingButton from '@src/components/share/apeiron/ApeironLoadingButton'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const ContentContainer = styled(Box)`
  padding: 16px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
`

const AppIconContainer = styled(Box)`
  width: 80px;
  aspect-ratio: 1;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: circle(50%);
`

const AppIcon = styled(NextImage)`
  aspect-ratio: 1;
  width: 70%;
`

const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  gap: 10px;
  min-width: 190px;
`

const Name = styled(Typography)`
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
`

const Description = styled(Typography)`
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: #80807e;
`

const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`

const Button = styled(ApeironLoadingButton)`
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  width: 136px;
  height: 50px;
  transition: border ${DEFAULT_ANIMATE_DURATION}ms,
    color ${DEFAULT_ANIMATE_DURATION}ms;
`

const ConnectButton = styled(Button)`
  background-color: unset;
  border: 2px solid #76b0ff;
  color: #76b0ff;
  :hover {
    border: 2px solid #4a97ff;
    color: #4a97ff;
  }
  :active {
    border: 2px solid #2c69bc;
    color: #2c69bc;
  }
`

const StyledDivider = styled(Divider)`
  border-color: rgba(255, 255, 255, 0.15);
`

export const BindWalletComponent: FC<WalletProps> = (props: WalletProps) => {
  const {
    className,
    icon,
    name,
    description,
    loading = false,
    onConnect,
    noDivider = false
  } = props

  const { t } = useTranslation()

  return (
    <Box className={className}>
      <ContentContainer>
        <AppIconContainer>
          <AppIcon src={icon} />
        </AppIconContainer>
        <TextContainer>
          <Name>{name}</Name>
          <Description>{description}</Description>
        </TextContainer>
        <ButtonContainer>
          <ConnectButton loading={loading} onClick={onConnect}>
            {t('common.button.bind')}
          </ConnectButton>
        </ButtonContainer>
      </ContentContainer>
      {!noDivider && <StyledDivider />}
    </Box>
  )
}

type WalletProps = {
  className?: string
  icon: string
  name: string
  description: string
  loading?: boolean
  onConnect: () => void
  noDivider?: boolean
}
