import OgDataDefault from '@src/components/ogData/OgDataDefault'
import OgDataNoIndex from '@src/components/ogData/OgDataNoIndex'
import { FC } from 'react'

const OgData: FC<Props> = () => {
  return (
    <>
      <OgDataDefault />
      <OgDataNoIndex />
    </>
  )
}

type Props = {}

export default OgData
