import { RoninSVG, useAccountContext } from '@apeiron/library'
import { Box, styled, Typography } from '@mui/material'
import { BindWalletComponent } from '@src/components/account/settings/BindWalletComponent'
import useMktErrorHandling from '@src/hooks/common/errorHandling/useMktErrorHandling'
import * as RA from 'ramda-adjunct'
import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`

const Title = styled(Typography)`
  color: #ffffff;
  text-transform: none;
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 12px;
`

const BindWallet: FC<Props> = (props: Props) => {
  const { className, title, onBindSuccess } = props

  const { t } = useTranslation()

  const { account, bindWalletAddress } = useAccountContext()

  const { showErrorPopup } = useMktErrorHandling()

  const isBinded = useMemo(
    () => RA.isNotNilOrEmpty(account.walletAddress),
    [account.walletAddress]
  )

  const [loading, setLoading] = useState(false)

  const handleOnLogin = useCallback(async () => {
    setLoading(true)
    try {
      const bindSuccess = await bindWalletAddress()

      if (bindSuccess && onBindSuccess) {
        onBindSuccess()
      }
    } catch (e) {
      showErrorPopup(e)
    } finally {
      setLoading(false)
    }
  }, [bindWalletAddress, onBindSuccess])

  return !isBinded ? (
    <Container className={className}>
      <Title>{title}</Title>
      <BindWalletComponent
        icon={RoninSVG}
        name={t('wallet.name.ronin')}
        description={t('account_setting.binding_mm')}
        loading={loading}
        onConnect={() => handleOnLogin()}
      />
    </Container>
  ) : (
    <></>
  )
}

type Props = {
  className?: string
  title: string
  onBindSuccess?: () => void
}

export default BindWallet
