import ENV_CONFIG from '@src/config'
import { VideoGameJsonLd } from 'next-seo'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @see https://www.npmjs.com/package/next-seo#videogame
 */
const SharedStructuredData: FC<Props> = () => {
  const { t } = useTranslation()

  const domain = ENV_CONFIG.WEB_URL.MARKETPLACE_LEGACY

  return (
    <VideoGameJsonLd
      applicationCategory={t('structured_data.common.video_game.category')}
      description={t('structured_data.common.video_game.description')}
      image={domain + 'images/og/image-800.jpg'}
      keywords={t('structured_data.common.video_game.keywords')}
      languageName={['English']}
      name={t('structured_data.common.video_game.name')}
      producerName={t('structured_data.common.video_game.producer')}
      producerUrl={domain}
    />
  )
}

type Props = {}

export default SharedStructuredData
