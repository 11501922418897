import { ProviderSwitchType } from '@src/constants/provider'
import { ROUTE } from '@src/constants/route'
import { StarProvider } from '@src/contexts/star/StarContext'
import usePath from '@src/hooks/common/router/usePath'
import { FC, ReactNode, useMemo } from 'react'

const ApeironProviderSwitcher: FC<Props> = props => {
  const { children } = props

  const { isCurrentPath } = usePath()

  const type = useMemo((): ProviderSwitchType => {
    if (
      isCurrentPath(ROUTE.MARKETPLACE_STAR_DETAIL, []) ||
      isCurrentPath(ROUTE.MARKETPLACE_STAR_MANAGE, []) ||
      isCurrentPath(ROUTE.INVENTORY_STAR_DETAIL, []) ||
      isCurrentPath(ROUTE.INVENTORY_STAR_MANAGE, []) ||
      isCurrentPath(ROUTE.FAVORITE_STAR_DETAIL, []) ||
      isCurrentPath(ROUTE.FAVORITE_STAR_MANAGE, []) ||
      isCurrentPath(ROUTE.OTHER_INVENTORY_STAR_DETAIL, []) ||
      isCurrentPath(ROUTE.OTHER_INVENTORY_STAR_MANAGE, [])
    ) {
      return ProviderSwitchType.Star
    }

    return ProviderSwitchType.None
  }, [isCurrentPath])

  switch (type) {
    case ProviderSwitchType.Star:
      return <StarProvider>{children}</StarProvider>

    default:
      return <>{children}</>
  }
}

type Props = {
  children?: ReactNode
}

export default ApeironProviderSwitcher
