import {
  deserializePageInfo,
  deserializeResponse,
  PageInfo,
  PublicAccount,
  publicAccountSchema
} from '@apeiron/library'
import { useLazyQuery } from '@apollo/client'
import { DEFAULT_PAGE_INFO } from '@src/constants/layout'
import graphql from '@src/graphql/query/getAccountInvitees'
import * as R from 'ramda'
import { useCallback, useState } from 'react'

const useGetAccountInvitees = (): Hook => {
  const [query, { loading }] = useLazyQuery(graphql)

  const [data, setData] = useState<PublicAccount[]>([])

  const [pageInfo, setPageInfo] = useState<PageInfo>(DEFAULT_PAGE_INFO)

  const fetch = useCallback(
    async (request: Request) => {
      const { isInvitee, pageSize, page } = request

      const response = await query({
        variables: {
          input: {
            isInvitee,
            pageSize,
            skip: (page - 1) * pageSize
          }
        }
      })

      const data = deserializeResponse(response)

      const accounts = R.map(node => {
        return publicAccountSchema.validateSync(node, {
          context: { data: node }
        })
      }, R.pathOr([], ['edges'], data)) as PublicAccount[]

      setData(accounts)

      const pageInfo = deserializePageInfo(response, { page, pageSize })

      setPageInfo(pageInfo)

      return {
        data: accounts,
        pageInfo
      }
    },
    [query]
  )

  return {
    data,
    loading,
    pageInfo,
    fetch
  }
}

type Request = {
  isInvitee: boolean
  pageSize: number
  page: number
}

type Response = { data: PublicAccount[]; pageInfo: PageInfo }

type Hook = {
  data: PublicAccount[]
  fetch: (request: Request) => Promise<Response>
  pageInfo: PageInfo
  loading: boolean
}

export default useGetAccountInvitees
