import { gql } from '@apollo/client'

export const ListingInfoFragment = gql`
  fragment ListingInfo on ListingInfo {
    currencyCode
    duration
    endPrice
    startDate
    startPrice

    roninSaleOrder {
      kind
      orderKindEnum
      asset {
        erc
        addr
        id
        quantity
      }
      expiredAt
      paymentToken
      startedAt
      basePrice
      endedAt
      endedPrice
      expectedState
      nonce
      marketFeePercentage
      signature
      orderStatus
    }
  }
`
