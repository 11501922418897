import { gql } from '@apollo/client'

const PageInfoCommonFragment = gql`
  fragment PageInfoCommon on CommonPageInfo {
    hasNextPage
    hasPreviousPage
  }
`

export const PageInfoLiteFragment = gql`
  fragment PageInfoLite on LitePageInfo {
    ...PageInfoCommon
    totalCount
  }
  ${PageInfoCommonFragment}
`

export const PageInfoSkipTotalFragment = gql`
  fragment PageInfoSkipTotal on PageInfo {
    ...PageInfoCommon
    endCursor
    startCursor
  }
  ${PageInfoCommonFragment}
`

export const PageInfoFragment = gql`
  fragment PageInfo on PageInfo {
    ...PageInfoSkipTotal
    totalCount
  }

  ${PageInfoSkipTotalFragment}
`
