import ENV_CONFIG from '@src/config'
import ABI from '@src/constants/abi'

/**
 * contract address list
 * @see https://docs.google.com/spreadsheets/d/1wgG8xd_swijl6bbAhkPO4kbYdPcDXPAXx2w2ycH3Q7Q/edit#gid=0
 */
const CONTRACT = {
  ADEPTUS: {
    CLAIM_REWARD: {
      abi: ABI.ADEPTUS,
      address: ENV_CONFIG.CONTRACT_ADDRESS.ADEPTUS,
      method: 'claimRewards'
    }
  },
  BOUNTY: {
    APOSTLE: {
      CREATE_BOUNTY: {
        abi: ABI.BOUNTY.APOSTLE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.BOUNTY.APOSTLE,
        method: 'createBounty'
      },
      FULFILL_BOUNTY: {
        abi: ABI.BOUNTY.APOSTLE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.BOUNTY.APOSTLE,
        method: 'fulfillBounty'
      },
      CANCEL_BOUNTY: {
        abi: ABI.BOUNTY.APOSTLE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.BOUNTY.APOSTLE,
        method: 'cancelBounty'
      }
    }
  },
  APOSTLE: {
    _INFO: {
      abi: ABI.APOSTLE.INFO,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APOSTLE,
      method: ''
    },
    GET_USER_ALLOWANCE: {
      abi: ABI.APOSTLE.INFO,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APOSTLE,
      method: 'isApprovedForAll'
    },
    REQUEST_ALLOWANCE: {
      abi: ABI.APOSTLE.INFO,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APOSTLE,
      method: 'setApprovalForAll'
    },
    SAFE_TRANSFER_FROM: {
      abi: ABI.APOSTLE.INFO,
      // godiverse abi = astronomical abi
      address: ENV_CONFIG.CONTRACT_ADDRESS.APOSTLE,
      method: 'safeTransferFrom'
    },
    MINT_SEASON_CALLER: {
      abi: ABI.APOSTLE.MINT,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APOSTLE_SEASON_CALLER,
      // minting for "reserved apostle" or "origin apostle ticket"
      method: 'requestMultiSeasonPurchase'
    },
    MINT_TICKET: {
      abi: ABI.APOSTLE_TICKET.MINT,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APOSTLE_TICKET,
      method: 'requestTicketMinting'
    },
    GET_MINTING_STRUCT: {
      abi: ABI.APOSTLE_TICKET.MINT,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APOSTLE_TICKET,
      method: 'getTicketMintingStructsByRequestId'
    },
    MANAGE_PLANET_APOSTLES: {
      abi: ABI.APOSTLE.MANAGE,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APOSTLE_MANAGE,
      method: 'manageRelicApostles'
    }
  },
  APOSTLE_FORGE: {
    RESET_TIME_INTERVAL: {
      abi: ABI.APOSTLE.FORGE,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APOSTLE_FORGE,
      method: 'forgeStageResetTime'
    },
    NFT_PLANET_STAGE_INFO: {
      abi: ABI.APOSTLE.FORGE,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APOSTLE_FORGE,
      method: 'planetLastForgeStageDataMapping'
    },
    SEED_PLANET_STAGE_INFO: {
      abi: ABI.APOSTLE.FORGE,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APOSTLE_FORGE,
      method: 'seedPlanetLastForgeStageDataMapping'
    },
    FORGE_RESERVED_APOSTLE: {
      abi: ABI.APOSTLE.FORGE,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APOSTLE_FORGE,
      method: 'forgeReservedApostle'
    }
  },
  TICKET: {
    ORIGINAL_APOSTLE_TICKET: {
      GET_USER_TICKETS: {
        abi: ABI.APOSTLE_TICKET.ORIGIN,
        address: ENV_CONFIG.CONTRACT_ADDRESS.TICKET.ORIGIN_APOSTLE,
        method: 'balanceOfBatch'
      },
      GET_USER_ALLOWANCE: {
        abi: ABI.APOSTLE_TICKET.ORIGIN,
        address: ENV_CONFIG.CONTRACT_ADDRESS.TICKET.ORIGIN_APOSTLE,
        method: 'isApprovedForAll'
      },
      REQUEST_ALLOWANCE: {
        abi: ABI.APOSTLE_TICKET.ORIGIN,
        address: ENV_CONFIG.CONTRACT_ADDRESS.TICKET.ORIGIN_APOSTLE,
        method: 'setApprovalForAll'
      }
    },
    S2_APOSTLE_TICKET: {
      GET_USER_TICKETS: {
        abi: ABI.GODIVERSE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.GODIVERSE,
        method: 'balanceOfBatch'
      },
      GET_USER_ALLOWANCE: {
        abi: ABI.APOSTLE_TICKET.S2,
        address: ENV_CONFIG.CONTRACT_ADDRESS.TICKET.S2_APOSTLE,
        method: 'isApprovedForAll'
      },
      REQUEST_ALLOWANCE: {
        abi: ABI.APOSTLE_TICKET.S2,
        address: ENV_CONFIG.CONTRACT_ADDRESS.TICKET.S2_APOSTLE,
        method: 'setApprovalForAll'
      }
    }
  },
  GODIVERSE: {
    _INFO: {
      abi: ABI.GODIVERSE,
      address: ENV_CONFIG.CONTRACT_ADDRESS.GODIVERSE,
      method: ''
    },
    GET_USER_ALLOWANCE: {
      abi: ABI.GODIVERSE,
      address: ENV_CONFIG.CONTRACT_ADDRESS.GODIVERSE,
      method: 'isApprovedForAll'
    },
    REQUEST_ALLOWANCE: {
      abi: ABI.GODIVERSE,
      address: ENV_CONFIG.CONTRACT_ADDRESS.GODIVERSE,
      method: 'setApprovalForAll'
    },
    SAFE_TRANSFER_FROM: {
      abi: ABI.GODIVERSE,
      // godiverse abi = astronomical abi
      address: ENV_CONFIG.CONTRACT_ADDRESS.GODIVERSE,
      method: 'safeTransferFrom'
    }
  },
  BATTLE_DEMO: {
    BURN_TIME: {
      abi: ABI.BATTLE_DEMO.BURN_TIME,
      address: ENV_CONFIG.CONTRACT_ADDRESS.BATTLE_DEMO_BURN_TIME,
      method: 'burnToken'
    },
    FRAGMENT: {
      abi: ABI.BATTLE_DEMO.FRAGMENT,
      address: ENV_CONFIG.CONTRACT_ADDRESS.FRAGMENT,
      method: 'balanceOfBatch'
    },
    GET_ALLOWANCE: {
      abi: ABI.BATTLE_DEMO.FRAGMENT,
      address: ENV_CONFIG.CONTRACT_ADDRESS.FRAGMENT,
      method: 'isApprovedForAll'
    },
    REQUEST_ALLOWANCE: {
      abi: ABI.BATTLE_DEMO.FRAGMENT,
      address: ENV_CONFIG.CONTRACT_ADDRESS.FRAGMENT,
      method: 'setApprovalForAll'
    }
  },
  BLANK: {
    abi: {
      POLYGON: [],
      RONIN: []
    },
    address: {
      POLYGON: '',
      RONIN: ''
    },
    method: ''
  },
  CONJUNCT: {
    ANIMA: {
      GET_PLANET_PRICE: {
        abi: ABI.PLANET.BREED,
        address: ENV_CONFIG.CONTRACT_ADDRESS.BREED,
        method: 'animaPrices'
      },
      GET_PLANET_PRICE_FOR_PRIMEVAL: {
        abi: ABI.PLANET.BREED,
        address: ENV_CONFIG.CONTRACT_ADDRESS.BREED,
        method: 'primevalAnimaPrices'
      },
      GET_USER_ALLOWANCE: {
        abi: ABI.CURRENCY.ANIMA,
        address: ENV_CONFIG.CONTRACT_ADDRESS.ANIMA,
        method: 'allowance'
      },
      GET_USER_BALANCE: {
        abi: ABI.CURRENCY.ANIMA,
        address: ENV_CONFIG.CONTRACT_ADDRESS.ANIMA,
        method: 'balanceOf'
      },
      REQUEST_ALLOWANCE: {
        abi: ABI.CURRENCY.ANIMA,
        address: ENV_CONFIG.CONTRACT_ADDRESS.ANIMA,
        method: 'approve'
      }
    },
    APRS: {
      GET_PLANET_PRICE: {
        abi: ABI.PLANET.BREED,
        address: ENV_CONFIG.CONTRACT_ADDRESS.BREED,
        method: 'aprsPrices'
      },
      GET_PLANET_PRICE_FOR_PRIMEVAL: {
        abi: ABI.PLANET.BREED,
        address: ENV_CONFIG.CONTRACT_ADDRESS.BREED,
        method: 'primevalAprsPrices'
      },
      GET_USER_ALLOWANCE: {
        abi: ABI.CURRENCY.APRS,
        address: ENV_CONFIG.CONTRACT_ADDRESS.APRS,
        method: 'allowance'
      },
      GET_USER_BALANCE: {
        abi: ABI.CURRENCY.APRS,
        address: ENV_CONFIG.CONTRACT_ADDRESS.APRS,
        method: 'balanceOf'
      },
      REQUEST_ALLOWANCE: {
        abi: ABI.CURRENCY.APRS,
        address: ENV_CONFIG.CONTRACT_ADDRESS.APRS,
        method: 'approve'
      }
    },
    BLACK_HOLE: {
      GET_ID: {
        abi: ABI.PLANET.BREED,
        address: ENV_CONFIG.CONTRACT_ADDRESS.BREED,
        method: 'miniBlackholeTokenId'
      },
      GET_PLANET_PRICE: {
        abi: ABI.PLANET.BREED,
        address: ENV_CONFIG.CONTRACT_ADDRESS.BREED,
        method: 'apeironGodiverseCollectionNumbers'
      },
      GET_PLANET_PRICE_FOR_PRIMEVAL: {
        abi: ABI.PLANET.BREED,
        address: ENV_CONFIG.CONTRACT_ADDRESS.BREED,
        method: 'primevalApeironGodiverseCollectionNumbers'
      },
      GET_USER_ALLOWANCE: {
        abi: ABI.GODIVERSE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.GODIVERSE,
        method: 'isApprovedForAll'
      },
      GET_USER_BALANCE: {
        abi: ABI.GODIVERSE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.GODIVERSE,
        method: 'balanceOf'
      },
      REQUEST_ALLOWANCE: {
        abi: ABI.GODIVERSE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.GODIVERSE,
        method: 'setApprovalForAll'
      }
    },
    CHECK_BREED: {
      abi: ABI.PLANET.BREED,
      address: ENV_CONFIG.CONTRACT_ADDRESS.BREED,
      method: 'BreedStructMap'
    },
    REQUEST_BREED: {
      abi: ABI.PLANET.BREED,
      address: ENV_CONFIG.CONTRACT_ADDRESS.BREED,
      method: 'requestBreedWithAnimus'
    }
  },
  DAILY_CHECK_IN: {
    CHECK_IN: {
      abi: ABI.DAILY_CHECK_IN,
      address: ENV_CONFIG.CONTRACT_ADDRESS.DAILY_CHECK_IN,
      method: 'dailyCheckin'
    },
    GET_MAKE_UP_PRICE: {
      abi: ABI.DAILY_CHECK_IN,
      address: ENV_CONFIG.CONTRACT_ADDRESS.DAILY_CHECK_IN,
      method: 'makeupPricesArray'
    },
    MAKE_UP_CHECK_IN: {
      abi: ABI.DAILY_CHECK_IN,
      address: ENV_CONFIG.CONTRACT_ADDRESS.DAILY_CHECK_IN,
      method: 'makeupCheckin'
    }
  },
  EXPEDITION: {
    // Black Hole + Support Apostle Ticket
    TYPE1: {
      CLAIM_REWARD: {
        abi: ABI.EXPEDITION.TYPE1.PRIZE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE1.PRIZES,
        method: 'claimTreasure'
      },
      GET_CURRENCY_ADDRESS: {
        abi: ABI.EXPEDITION.TYPE1.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE1.STAKING_AND_KEYS,
        method: 'exchangeToken'
      },
      GET_EXPEDITION_STATUS: {
        abi: ABI.EXPEDITION.TYPE1.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE1.INFO,
        method: 'getExpeditionState'
      },
      GET_KEY_BALANCE: {
        abi: ABI.EXPEDITION.TYPE1.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE1.STAKING_AND_KEYS,
        method: 'userKeys'
      },
      GET_KEY_EXCHANGE_RATE: {
        abi: ABI.EXPEDITION.TYPE1.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE1.STAKING_AND_KEYS,
        method: 'exchangeRateForKey'
      },
      GET_STAKED_ASSETS: {
        abi: ABI.EXPEDITION.TYPE1.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE1.STAKING_AND_KEYS,
        method: 'getStakedAssets'
      },
      GET_USER_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE1.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE1.INFO,
        method: 'getUserExpedition'
      },
      JOIN_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE1.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE1.INFO,
        method: 'joinExpedition'
      },
      REQUEST_KEY_TO_TOKEN: {
        abi: ABI.EXPEDITION.TYPE1.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE1.INFO,
        method: 'exchangeKeyToToken'
      },
      REQUEST_STAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE1.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE1.INFO,
        method: 'stakeNFT'
      },
      REQUEST_STAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE1.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE1.INFO,
        method: 'stakeFT'
      },
      REQUEST_TOKEN_TO_KEY: {
        abi: ABI.EXPEDITION.TYPE1.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE1.INFO,
        method: 'exchangeTokenToKey'
      },
      REQUEST_UNSTAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE1.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE1.INFO,
        method: 'unstakeNFT'
      },
      REQUEST_UNSTAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE1.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE1.INFO,
        method: 'unstakeFT'
      }
    },
    // Star + Astro + Support Apostle Ticket
    TYPE2: {
      CLAIM_REWARD: {
        abi: ABI.EXPEDITION.TYPE1.PRIZE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE2.PRIZES,
        method: 'claimTreasure'
      },
      GET_CURRENCY_ADDRESS: {
        abi: ABI.EXPEDITION.TYPE2.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE2.STAKING_AND_KEYS,
        method: 'exchangeToken'
      },
      GET_EXPEDITION_STATUS: {
        abi: ABI.EXPEDITION.TYPE2.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE2.INFO,
        method: 'getExpeditionState'
      },
      GET_KEY_BALANCE: {
        abi: ABI.EXPEDITION.TYPE2.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE2.STAKING_AND_KEYS,
        method: 'userKeys'
      },
      GET_KEY_EXCHANGE_RATE: {
        abi: ABI.EXPEDITION.TYPE2.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE2.STAKING_AND_KEYS,
        method: 'exchangeRateForKey'
      },
      GET_STAKED_ASSETS: {
        abi: ABI.EXPEDITION.TYPE2.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE2.STAKING_AND_KEYS,
        method: 'getStakedAssets'
      },
      GET_USER_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE2.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE2.INFO,
        method: 'getUserExpedition'
      },
      JOIN_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE2.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE2.INFO,
        method: 'joinExpedition'
      },
      REQUEST_KEY_TO_TOKEN: {
        abi: ABI.EXPEDITION.TYPE2.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE2.INFO,
        method: 'exchangeKeyToToken'
      },
      REQUEST_STAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE2.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE2.INFO,
        method: 'stakeNFT'
      },
      REQUEST_STAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE2.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE2.INFO,
        method: 'stakeFT'
      },
      REQUEST_TOKEN_TO_KEY: {
        abi: ABI.EXPEDITION.TYPE2.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE2.INFO,
        method: 'exchangeTokenToKey'
      },
      REQUEST_UNSTAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE2.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE2.INFO,
        method: 'unstakeNFT'
      },
      REQUEST_UNSTAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE2.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE2.INFO,
        method: 'unstakeFT'
      }
    },
    // Emblem + Support Apostle Ticket
    TYPE3: {
      CLAIM_REWARD: {
        abi: ABI.EXPEDITION.TYPE3.PRIZE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE3.PRIZES,
        method: 'claimTreasure'
      },
      GET_CURRENCY_ADDRESS: {
        abi: ABI.EXPEDITION.TYPE3.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE3.STAKING_AND_KEYS,
        method: 'exchangeToken'
      },
      GET_EXPEDITION_STATUS: {
        abi: ABI.EXPEDITION.TYPE3.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE3.INFO,
        method: 'getExpeditionState'
      },
      GET_KEY_BALANCE: {
        abi: ABI.EXPEDITION.TYPE3.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE3.STAKING_AND_KEYS,
        method: 'userKeys'
      },
      GET_KEY_EXCHANGE_RATE: {
        abi: ABI.EXPEDITION.TYPE3.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE3.STAKING_AND_KEYS,
        method: 'exchangeRateForKey'
      },
      GET_STAKED_ASSETS: {
        abi: ABI.EXPEDITION.TYPE3.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE3.STAKING_AND_KEYS,
        method: 'getStakedAssets'
      },
      GET_USER_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE3.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE3.INFO,
        method: 'getUserExpedition'
      },
      JOIN_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE3.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE3.INFO,
        method: 'joinExpedition'
      },
      REQUEST_KEY_TO_TOKEN: {
        abi: ABI.EXPEDITION.TYPE3.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE3.INFO,
        method: 'exchangeKeyToToken'
      },
      REQUEST_STAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE3.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE3.INFO,
        method: 'stakeNFT'
      },
      REQUEST_STAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE3.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE3.INFO,
        method: 'stakeFT'
      },
      REQUEST_TOKEN_TO_KEY: {
        abi: ABI.EXPEDITION.TYPE3.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE3.INFO,
        method: 'exchangeTokenToKey'
      },
      REQUEST_UNSTAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE3.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE3.INFO,
        method: 'unstakeNFT'
      },
      REQUEST_UNSTAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE3.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE3.INFO,
        method: 'unstakeFT'
      }
    },
    // Emblem + Support Apostle Stake
    TYPE4: {
      CLAIM_REWARD: {
        abi: ABI.EXPEDITION.TYPE4.PRIZE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE4.PRIZES,
        method: 'claimTreasure'
      },
      GET_CURRENCY_ADDRESS: {
        abi: ABI.EXPEDITION.TYPE4.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE4.STAKING_AND_KEYS,
        method: 'exchangeToken'
      },
      GET_EXPEDITION_STATUS: {
        abi: ABI.EXPEDITION.TYPE4.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE4.INFO,
        method: 'getExpeditionState'
      },
      GET_KEY_BALANCE: {
        abi: ABI.EXPEDITION.TYPE4.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE4.STAKING_AND_KEYS,
        method: 'userKeys'
      },
      GET_KEY_EXCHANGE_RATE: {
        abi: ABI.EXPEDITION.TYPE4.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE4.STAKING_AND_KEYS,
        method: 'exchangeRateForKey'
      },
      GET_STAKED_ASSETS: {
        abi: ABI.EXPEDITION.TYPE4.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE4.STAKING_AND_KEYS,
        method: 'getStakedAssets'
      },
      GET_USER_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE4.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE4.INFO,
        method: 'getUserExpedition'
      },
      JOIN_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE4.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE4.INFO,
        method: 'joinExpedition'
      },
      REQUEST_KEY_TO_TOKEN: {
        abi: ABI.EXPEDITION.TYPE4.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE4.INFO,
        method: 'exchangeKeyToToken'
      },
      REQUEST_STAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE4.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE4.INFO,
        method: 'stakeNFT'
      },
      REQUEST_STAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE4.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE4.INFO,
        method: 'stakeFT'
      },
      REQUEST_TOKEN_TO_KEY: {
        abi: ABI.EXPEDITION.TYPE4.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE4.INFO,
        method: 'exchangeTokenToKey'
      },
      REQUEST_UNSTAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE4.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE4.INFO,
        method: 'unstakeNFT'
      },
      REQUEST_UNSTAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE4.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE4.INFO,
        method: 'unstakeFT'
      }
    },
    // Off Chain Reward + Support Apostle Stake
    TYPE5: {
      CLAIM_REWARD: {
        abi: ABI.EXPEDITION.TYPE5.PRIZE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE5.PRIZES,
        method: 'claimTreasure'
      },
      GET_CURRENCY_ADDRESS: {
        abi: ABI.EXPEDITION.TYPE5.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE5.STAKING_AND_KEYS,
        method: 'exchangeToken'
      },
      GET_EXPEDITION_STATUS: {
        abi: ABI.EXPEDITION.TYPE5.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE5.INFO,
        method: 'getExpeditionState'
      },
      GET_KEY_BALANCE: {
        abi: ABI.EXPEDITION.TYPE5.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE5.STAKING_AND_KEYS,
        method: 'userKeys'
      },
      GET_KEY_EXCHANGE_RATE: {
        abi: ABI.EXPEDITION.TYPE5.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE5.STAKING_AND_KEYS,
        method: 'exchangeRateForKey'
      },
      GET_STAKED_ASSETS: {
        abi: ABI.EXPEDITION.TYPE5.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE5.STAKING_AND_KEYS,
        method: 'getStakedAssets'
      },
      GET_USER_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE5.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE5.INFO,
        method: 'getUserExpedition'
      },
      JOIN_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE5.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE5.INFO,
        method: 'joinExpedition'
      },
      REQUEST_KEY_TO_TOKEN: {
        abi: ABI.EXPEDITION.TYPE5.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE5.INFO,
        method: 'exchangeKeyToToken'
      },
      REQUEST_STAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE5.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE5.INFO,
        method: 'stakeNFT'
      },
      REQUEST_STAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE5.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE5.INFO,
        method: 'stakeFT'
      },
      REQUEST_TOKEN_TO_KEY: {
        abi: ABI.EXPEDITION.TYPE5.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE5.INFO,
        method: 'exchangeTokenToKey'
      },
      REQUEST_UNSTAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE5.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE5.INFO,
        method: 'unstakeNFT'
      },
      REQUEST_UNSTAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE5.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE5.INFO,
        method: 'unstakeFT'
      }
    },
    // On Chain Reward (Animus) + Support Apostle Stake
    TYPE6: {
      CLAIM_REWARD: {
        abi: ABI.EXPEDITION.TYPE6.PRIZE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE6.PRIZES,
        method: 'claimTreasure'
      },
      GET_CURRENCY_ADDRESS: {
        abi: ABI.EXPEDITION.TYPE6.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE6.STAKING_AND_KEYS,
        method: 'exchangeToken'
      },
      GET_EXPEDITION_STATUS: {
        abi: ABI.EXPEDITION.TYPE6.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE6.INFO,
        method: 'getExpeditionState'
      },
      GET_KEY_BALANCE: {
        abi: ABI.EXPEDITION.TYPE6.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE6.STAKING_AND_KEYS,
        method: 'userKeys'
      },
      GET_KEY_EXCHANGE_RATE: {
        abi: ABI.EXPEDITION.TYPE6.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE6.STAKING_AND_KEYS,
        method: 'exchangeRateForKey'
      },
      GET_STAKED_ASSETS: {
        abi: ABI.EXPEDITION.TYPE6.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE6.STAKING_AND_KEYS,
        method: 'getStakedAssets'
      },
      GET_USER_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE6.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE6.INFO,
        method: 'getUserExpedition'
      },
      JOIN_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE6.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE6.INFO,
        method: 'joinExpedition'
      },
      REQUEST_KEY_TO_TOKEN: {
        abi: ABI.EXPEDITION.TYPE6.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE6.INFO,
        method: 'exchangeKeyToToken'
      },
      REQUEST_STAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE6.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE6.INFO,
        method: 'stakeNFT'
      },
      REQUEST_STAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE6.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE6.INFO,
        method: 'stakeFT'
      },
      REQUEST_TOKEN_TO_KEY: {
        abi: ABI.EXPEDITION.TYPE6.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE6.INFO,
        method: 'exchangeTokenToKey'
      },
      REQUEST_UNSTAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE6.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE6.INFO,
        method: 'unstakeNFT'
      },
      REQUEST_UNSTAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE6.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE6.INFO,
        method: 'unstakeFT'
      }
    },
    // On Chain Reward (Star) + Support Apostle Stake
    TYPE7: {
      CLAIM_REWARD: {
        abi: ABI.EXPEDITION.TYPE7.PRIZE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE7.PRIZES,
        method: 'claimTreasure'
      },
      GET_CURRENCY_ADDRESS: {
        abi: ABI.EXPEDITION.TYPE7.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE7.STAKING_AND_KEYS,
        method: 'exchangeToken'
      },
      GET_EXPEDITION_STATUS: {
        abi: ABI.EXPEDITION.TYPE7.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE7.INFO,
        method: 'getExpeditionState'
      },
      GET_KEY_BALANCE: {
        abi: ABI.EXPEDITION.TYPE7.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE7.STAKING_AND_KEYS,
        method: 'userKeys'
      },
      GET_KEY_EXCHANGE_RATE: {
        abi: ABI.EXPEDITION.TYPE7.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE7.STAKING_AND_KEYS,
        method: 'exchangeRateForKey'
      },
      GET_STAKED_ASSETS: {
        abi: ABI.EXPEDITION.TYPE7.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE7.STAKING_AND_KEYS,
        method: 'getStakedAssets'
      },
      GET_USER_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE7.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE7.INFO,
        method: 'getUserExpedition'
      },
      JOIN_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE7.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE7.INFO,
        method: 'joinExpedition'
      },
      REQUEST_KEY_TO_TOKEN: {
        abi: ABI.EXPEDITION.TYPE7.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE7.INFO,
        method: 'exchangeKeyToToken'
      },
      REQUEST_STAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE7.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE7.INFO,
        method: 'stakeNFT'
      },
      REQUEST_STAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE7.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE7.INFO,
        method: 'stakeFT'
      },
      REQUEST_TOKEN_TO_KEY: {
        abi: ABI.EXPEDITION.TYPE7.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE7.INFO,
        method: 'exchangeTokenToKey'
      },
      REQUEST_UNSTAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE7.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE7.INFO,
        method: 'unstakeNFT'
      },
      REQUEST_UNSTAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE7.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE7.INFO,
        method: 'unstakeFT'
      }
    },
    // Support Axie Stake
    TYPE8: {
      CLAIM_REWARD: {
        abi: ABI.EXPEDITION.TYPE8.PRIZE,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE8.PRIZES,
        method: 'claimTreasure'
      },
      GET_CURRENCY_ADDRESS: {
        abi: ABI.EXPEDITION.TYPE8.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE8.STAKING_AND_KEYS,
        method: 'exchangeToken'
      },
      GET_EXPEDITION_STATUS: {
        abi: ABI.EXPEDITION.TYPE8.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE8.INFO,
        method: 'getExpeditionState'
      },
      GET_KEY_BALANCE: {
        abi: ABI.EXPEDITION.TYPE8.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE8.STAKING_AND_KEYS,
        method: 'userKeys'
      },
      GET_KEY_EXCHANGE_RATE: {
        abi: ABI.EXPEDITION.TYPE8.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE8.STAKING_AND_KEYS,
        method: 'exchangeRateForKey'
      },
      GET_STAKED_ASSETS: {
        abi: ABI.EXPEDITION.TYPE8.KEY,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE8.STAKING_AND_KEYS,
        method: 'getStakedAssets'
      },
      GET_USER_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE8.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE8.INFO,
        method: 'getUserExpedition'
      },
      JOIN_EXPEDITION: {
        abi: ABI.EXPEDITION.TYPE8.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE8.INFO,
        method: 'joinExpedition'
      },
      REQUEST_KEY_TO_TOKEN: {
        abi: ABI.EXPEDITION.TYPE8.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE8.INFO,
        method: 'exchangeKeyToToken'
      },
      REQUEST_STAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE8.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE8.INFO,
        method: 'stakeNFT'
      },
      REQUEST_STAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE8.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE8.INFO,
        method: 'stakeFT'
      },
      REQUEST_TOKEN_TO_KEY: {
        abi: ABI.EXPEDITION.TYPE8.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE8.INFO,
        method: 'exchangeTokenToKey'
      },
      REQUEST_UNSTAKE_ASSETS: {
        abi: ABI.EXPEDITION.TYPE8.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE8.INFO,
        method: 'unstakeNFT'
      },
      REQUEST_UNSTAKE_EXTRA_TOKEN: {
        abi: ABI.EXPEDITION.TYPE8.INFO,
        address: ENV_CONFIG.CONTRACT_ADDRESS.EXPEDITION.TYPE8.INFO,
        method: 'unstakeFT'
      }
    }
  },
  LIQUIDITY_POOL: {
    QUOTE: {
      abi: ABI.LIQUIDITY.PERMISSIONED_ROUTER,
      address: ENV_CONFIG.CONTRACT_ADDRESS.LIQUIDITY_POOL.PERMISSIONED_ROUTER,
      method: 'quote'
    },
    ADD_LIQUIDITY_RON: {
      abi: ABI.LIQUIDITY.PERMISSIONED_ROUTER,
      address: ENV_CONFIG.CONTRACT_ADDRESS.LIQUIDITY_POOL.PERMISSIONED_ROUTER,
      method: 'addLiquidityRON'
    },
    REMOVE_LIQUIDITY_RON: {
      abi: ABI.LIQUIDITY.PERMISSIONED_ROUTER,
      address: ENV_CONFIG.CONTRACT_ADDRESS.LIQUIDITY_POOL.PERMISSIONED_ROUTER,
      method: 'removeLiquidityRON'
    }
  },
  // for ronin
  MARKETPLACE: {
    MAKER_NONCE: {
      abi: ABI.MARKETPLACE.APEIRON_MARKETPLACE_GATEWAY_PROXY,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APEIRON_MARKETPLACE_GATEWAY_PROXY,
      method: 'getMakerNonce'
    },
    GET_STATE: {
      abi: ABI.MARKETPLACE.APEIRON_MARKETPLACE_GATEWAY_PROXY,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APEIRON_MARKETPLACE_GATEWAY_PROXY,
      method: 'getState'
    },
    GATEWAY_INTERACT_WITH: {
      abi: ABI.MARKETPLACE.MARKET_GATEWAY_PROXY,
      address: ENV_CONFIG.CONTRACT_ADDRESS.MARKET_GATEWAY_PROXY,
      method: 'interactWith'
    }
  },
  // for polygon
  MARKETPLACE_721: {
    BUY: {
      abi: ABI.MARKETPLACE.MKTP721,
      address: ENV_CONFIG.CONTRACT_ADDRESS.MARKETPLACE_721,
      method: 'buyAuctionItem'
    },
    CANCEL_LISTING: {
      abi: ABI.MARKETPLACE.MKTP721,
      address: ENV_CONFIG.CONTRACT_ADDRESS.MARKETPLACE_721,
      method: 'cancelAuctionListing'
    },
    CREATE_LISTING: {
      abi: ABI.MARKETPLACE.MKTP721,
      address: ENV_CONFIG.CONTRACT_ADDRESS.MARKETPLACE_721,
      method: 'listAuctionItem'
    },
    GET_PRICE_FOR_AUCTION: {
      abi: ABI.MARKETPLACE.MKTP721,
      address: ENV_CONFIG.CONTRACT_ADDRESS.MARKETPLACE_721,
      method: 'getAuctionItemPrice'
    },
    UPDATE_LISTING: {
      abi: ABI.MARKETPLACE.MKTP721,
      address: ENV_CONFIG.CONTRACT_ADDRESS.MARKETPLACE_721,
      method: 'updateAuctionItem'
    }
  },
  // for polygon
  MARKETPLACE_1155: {
    BUY: {
      abi: ABI.MARKETPLACE.MKTP1155,
      address: ENV_CONFIG.CONTRACT_ADDRESS.MARKETPLACE_1155,
      method: 'buyAuctionItem'
    },
    CANCEL_LISTING: {
      abi: ABI.MARKETPLACE.MKTP1155,
      address: ENV_CONFIG.CONTRACT_ADDRESS.MARKETPLACE_1155,
      method: 'cancelAuctionListing'
    },
    CREATE_LISTING: {
      abi: ABI.MARKETPLACE.MKTP1155,
      address: ENV_CONFIG.CONTRACT_ADDRESS.MARKETPLACE_1155,
      method: 'listAuctionItem'
    },
    UPDATE_LISTING: {
      abi: ABI.MARKETPLACE.MKTP1155,
      address: ENV_CONFIG.CONTRACT_ADDRESS.MARKETPLACE_1155,
      method: 'updateAuctionItem'
    }
  },
  MORPH: {
    CHECK_BORN: {
      abi: ABI.PLANET.BORN,
      address: ENV_CONFIG.CONTRACT_ADDRESS.BORN,
      method: 'BornStructMap'
    },
    REQUEST_BORN: {
      abi: ABI.PLANET.BORN,
      address: ENV_CONFIG.CONTRACT_ADDRESS.BORN,
      method: 'requestBorn'
    }
  },
  PLANET: {
    _INFO: {
      abi: ABI.PLANET.INFO,
      address: ENV_CONFIG.CONTRACT_ADDRESS.PLANET,
      method: ''
    },
    GET_USER_ALLOWANCE: {
      abi: ABI.PLANET.INFO,
      address: ENV_CONFIG.CONTRACT_ADDRESS.PLANET,
      method: 'isApprovedForAll'
    },
    REQUEST_ALLOWANCE: {
      abi: ABI.PLANET.INFO,
      address: ENV_CONFIG.CONTRACT_ADDRESS.PLANET,
      method: 'setApprovalForAll'
    },
    SAFE_TRANSFER_FROM: {
      abi: ABI.PLANET.INFO,
      address: ENV_CONFIG.CONTRACT_ADDRESS.PLANET,
      method: 'safeTransferFrom'
    }
  },
  PRELOAD: {
    GET_BLACK_HOLE_TOKEN_ID: {
      abi: ABI.PLANET.BREED,
      address: ENV_CONFIG.CONTRACT_ADDRESS.BREED,
      method: 'miniBlackholeTokenId'
    },
    GET_BREED_IS_PRIMEVAL: {
      abi: ABI.PLANET.BREED,
      address: ENV_CONFIG.CONTRACT_ADDRESS.BREED,
      method: 'isPrimevalActive'
    },
    GET_BREED_TIME_LIMIT: {
      abi: ABI.PLANET.BREED,
      address: ENV_CONFIG.CONTRACT_ADDRESS.BREED,
      method: 'normalBreedBaseInterval'
    }
  },
  VESTING: {
    CLAIM: {
      abi: ABI.REWARD.VESTING,
      address: ENV_CONFIG.CONTRACT_ADDRESS.VESTING,
      method: 'release'
    },
    GET_CLAIMABLE_AND_CLAIMED: {
      abi: ABI.REWARD.VESTING,
      address: ENV_CONFIG.CONTRACT_ADDRESS.VESTING,
      method: 'getAccountClaimableAndReleasedAmount'
    },
    GET_VESTED: {
      abi: ABI.REWARD.VESTING,
      address: ENV_CONFIG.CONTRACT_ADDRESS.VESTING,
      method: 'getAccountTotalAmount'
    }
  },
  VIP: {
    TOKEN_STAKING: {
      abi: ABI.VIP.TOKEN_STAKING,
      address: ENV_CONFIG.CONTRACT_ADDRESS.VIP_STAKING,
      method: 'stakeToken'
    },
    UNSTAKE_TOKEN: {
      abi: ABI.VIP.TOKEN_STAKING,
      address: ENV_CONFIG.CONTRACT_ADDRESS.VIP_STAKING,
      method: 'unstakeToken'
    }
  },
  STAR: {
    _INFO: {
      abi: ABI.STAR.INFO,
      address: ENV_CONFIG.CONTRACT_ADDRESS.STAR,
      method: ''
    },
    ATTACH_GODIVERSE: {
      abi: ABI.STAR.ATTACH_GODIVERSE,
      address: ENV_CONFIG.CONTRACT_ADDRESS.STAR_ATTACH,
      method: 'attachAndDetachGodiverses'
    },
    GET_USER_ALLOWANCE: {
      abi: ABI.PLANET.INFO,
      address: ENV_CONFIG.CONTRACT_ADDRESS.STAR,
      method: 'isApprovedForAll'
    },
    REQUEST_ALLOWANCE: {
      abi: ABI.PLANET.INFO,
      address: ENV_CONFIG.CONTRACT_ADDRESS.STAR,
      method: 'setApprovalForAll'
    },
    SAFE_TRANSFER_FROM: {
      abi: ABI.STAR.INFO,
      address: ENV_CONFIG.CONTRACT_ADDRESS.STAR,
      method: 'safeTransferFrom'
    },
    OWNER_ATTACH_PLANET: {
      abi: ABI.ORBITAL_TRACK.CALLER,
      address: ENV_CONFIG.CONTRACT_ADDRESS.STAR_ORBITAL_TRACK_CALLER,
      method: 'starOwnerAttachOwnPlanet'
    },
    OWNER_DETACH_PLANET: {
      abi: ABI.ORBITAL_TRACK.CALLER,
      address: ENV_CONFIG.CONTRACT_ADDRESS.STAR_ORBITAL_TRACK_CALLER,
      method: 'starOwnerDetachPlanet'
    },
    RENTER_ATTACH_PLANET: {
      abi: ABI.ORBITAL_TRACK.CALLER,
      address: ENV_CONFIG.CONTRACT_ADDRESS.STAR_ORBITAL_TRACK_CALLER,
      method: 'rentingOrbitalTrack',
      spenderAddress: ENV_CONFIG.CONTRACT_ADDRESS.STAR_ORBITAL_TRACK
    },
    RENTER_DETACH_PLANET: {
      abi: ABI.ORBITAL_TRACK.CALLER,
      address: ENV_CONFIG.CONTRACT_ADDRESS.STAR_ORBITAL_TRACK_CALLER,
      method: 'planetOwnerDetachPlanet'
    },
    RENEW_RENTAL: {
      abi: ABI.ORBITAL_TRACK.CALLER,
      address: ENV_CONFIG.CONTRACT_ADDRESS.STAR_ORBITAL_TRACK_CALLER,
      method: 'renewRentingOrbitalTrack'
    },
    SAVE_RENTAL_SETTING: {
      abi: ABI.ORBITAL_TRACK.INFO,
      address: ENV_CONFIG.CONTRACT_ADDRESS.STAR_ORBITAL_TRACK,
      method: 'updateRentalSetting'
    }
  },
  THIRD_PARTY_NFT: {
    GET_USER_ALLOWANCE: {
      abi: ABI.PLANET.INFO,
      address: ENV_CONFIG.CONTRACT_ADDRESS.THIRD_PARTY_NFT,
      method: 'isApprovedForAll'
    },
    REQUEST_ALLOWANCE: {
      abi: ABI.PLANET.INFO,
      address: ENV_CONFIG.CONTRACT_ADDRESS.THIRD_PARTY_NFT,
      method: 'setApprovalForAll'
    },
    SAFE_TRANSFER_FROM: {
      abi: ABI.THIRD_PARTY_NFT,
      address: ENV_CONFIG.CONTRACT_ADDRESS.THIRD_PARTY_NFT,
      method: 'safeTransferFrom'
    }
  },
  WETH: {
    _INFO: {
      abi: ABI.CURRENCY.WETH,
      address: ENV_CONFIG.CONTRACT_ADDRESS.WETH,
      method: ''
    },
    GET_USER_ALLOWANCE: {
      abi: ABI.CURRENCY.WETH,
      address: ENV_CONFIG.CONTRACT_ADDRESS.WETH,
      method: 'allowance'
    },
    GET_USER_BALANCE: {
      abi: ABI.CURRENCY.WETH,
      address: ENV_CONFIG.CONTRACT_ADDRESS.WETH,
      method: 'balanceOf'
    },
    REQUEST_ALLOWANCE: {
      abi: ABI.CURRENCY.WETH,
      address: ENV_CONFIG.CONTRACT_ADDRESS.WETH,
      method: 'approve'
    }
  },
  USDC: {
    GET_USER_ALLOWANCE: {
      abi: ABI.CURRENCY.USDC,
      address: ENV_CONFIG.CONTRACT_ADDRESS.USDC,
      method: 'allowance'
    },
    GET_USER_BALANCE: {
      abi: ABI.CURRENCY.USDC,
      address: ENV_CONFIG.CONTRACT_ADDRESS.USDC,
      method: 'balanceOf'
    },
    REQUEST_ALLOWANCE: {
      abi: ABI.CURRENCY.USDC,
      address: ENV_CONFIG.CONTRACT_ADDRESS.USDC,
      method: 'approve'
    }
  },
  WRON: {
    GET_USER_ALLOWANCE: {
      abi: ABI.CURRENCY.WRON,
      address: ENV_CONFIG.CONTRACT_ADDRESS.WRON,
      method: 'allowance'
    },
    GET_USER_BALANCE: {
      abi: ABI.CURRENCY.WRON,
      address: ENV_CONFIG.CONTRACT_ADDRESS.WRON,
      method: 'balanceOf'
    },
    REQUEST_ALLOWANCE: {
      abi: ABI.CURRENCY.WRON,
      address: ENV_CONFIG.CONTRACT_ADDRESS.WRON,
      method: 'approve'
    }
  },
  APRS_WRON: {
    GET_USER_ALLOWANCE: {
      abi: ABI.CURRENCY.APRS_WRON,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APRS_WRON,
      method: 'allowance'
    },
    GET_USER_BALANCE: {
      abi: ABI.CURRENCY.APRS_WRON,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APRS_WRON,
      method: 'balanceOf'
    },
    REQUEST_ALLOWANCE: {
      abi: ABI.CURRENCY.APRS_WRON,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APRS_WRON,
      method: 'approve'
    },
    GET_RESERVES: {
      abi: ABI.CURRENCY.APRS_WRON,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APRS_WRON,
      method: 'getReserves'
    },
    GET_TOTAL_SUPPLY: {
      abi: ABI.CURRENCY.APRS_WRON,
      address: ENV_CONFIG.CONTRACT_ADDRESS.APRS_WRON,
      method: 'totalSupply'
    }
  }
}

export default CONTRACT
