import { gql } from '@apollo/client'
import { TreasurePoolFragment } from '@src/graphql/fragment/treasurePool'

export default gql`
  query GetLatestExpeditions($input: GetLatestExpeditionInput!) {
    getLatestExpeditions(input: $input) {
      chainExpeditionID
      description
      expeditionEndTime
      expeditionStartTime
      expeditionType
      name
      staticAssetKey
      menuItemStepAssetKeys
      tokenRelated {
        exchangeKeyTokens
        extraVaporStakeTokens
      }
      treasurePool {
        ...TreasurePool
      }
    }
  }
  ${TreasurePoolFragment}
`
