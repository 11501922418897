import { SortByItem } from '@src/types/filter'

// enum - star list mode (frontend only)
export enum StarListMode {
  EMPORIUM = 0,
  INVENTORY,
  OTHERS_INVENTORY,
  FAVORITE,
  POPUP_SELECT_INVENTORY
}

// enum - slot changable state (frontend only)
export enum SlotChangableState {
  NoForTypeMismatch = 0,
  NoForTierNotEnough,
  NoForOrbitNotEmpty,
  NoForOrbitLimitTooLow,
  NoForMissingData,
  Changable // can change
}

// enum - galaxy id (align with backend)
export enum GalaxyID {
  Korea = 0,
  Slavic,
  Egypt,
  Babylon,
  Shinto,
  Norse,
  SEA2,
  Latam,
  Tao,
  Hindu,
  Greek,
  AxisMundi,
  ChaosSMBH,
  Wandering
}

// enum - costellation type (align with backend)
export enum ConstellationTypeID {
  Major = 0,
  Minor,
  None
}

// enum - orbital slot type (align with backend)
export enum OrbitalSlotType {
  SunSlot = 0,
  AsteroidBeltSlot,
  KuiperBeltSlot,
  CometSlot,
  SupernovaSlot
}

// enum - permanent super nova type (align with backend)
export enum PermanentSupernovaType {
  NonePermanentSupernova = 0,
  HavePermanentSupernova
}

// enum - celestial object type (align with backend)
export enum CelestialObjectType {
  None = 0,
  BlackHole,
  DarkNebula
}

// enum - star sort options (align with backend)
export enum StarSortBy {
  StarIdAsc = 'IDAsc',
  StarIdDesc = 'IDDesc',
  PriceAsc = 'PriceAsc',
  PriceDesc = 'PriceDesc',
  ListAtDesc = 'ListingTimeDesc',
  UpdateAtDesc = 'UpdateAtDesc',
  ConstellationDigitAsc = 'ConstellationDigitAsc'
}

// enum - star status (align with backend)
export enum StarStatus {
  Enable = 'Enable',
  ComingSoon = 'ComingSoon'
}

// enum - layout type (frontend only)
export enum StarExplainType {
  Buff = 0,
  Text,
  Slot
}

// enum - core effect (align with backend)
export enum StarCoreEffectType {
  Gassy = 0,
  Thunderbolt,
  Flame,
  Sparkling,
  None
}

// enum - action to mark if attach or detach slot (frontend only)
export enum StarAttachGodiverseAction {
  Attach = 'attach',
  Detach = 'detach'
}

// enum - special filter
export enum StarSpecialFilter {
  celestial = 'hasCelestialObject',
  ultranova = 'hasPermanentNova'
}

// const - use on sorting general star list
export const StarNormalSortItems: SortByItem<StarSortBy>[] = [
  { id: 0, nameId: 'list.sort_price_asc', value: StarSortBy.PriceAsc },
  { id: 1, nameId: 'list.sort_price_desc', value: StarSortBy.PriceDesc },
  {
    id: 2,
    nameId: 'list.sort_id_asc',
    value: StarSortBy.StarIdAsc
  },
  {
    id: 3,
    nameId: 'list.sort_id_desc',
    value: StarSortBy.StarIdDesc
  },
  {
    id: 4, // latest
    nameId: 'list.sort_updated_at_desc',
    value: StarSortBy.UpdateAtDesc
  }
]

// const - use on sorting emporium star list
export const StarEmporiumSortItems: SortByItem<StarSortBy>[] = [
  { id: 0, nameId: 'list.sort_price_asc', value: StarSortBy.PriceAsc },
  { id: 1, nameId: 'list.sort_price_desc', value: StarSortBy.PriceDesc },
  {
    id: 2,
    nameId: 'list.sort_id_asc',
    value: StarSortBy.StarIdAsc
  },
  {
    id: 3,
    nameId: 'list.sort_id_desc',
    value: StarSortBy.StarIdDesc
  },
  {
    id: 4, // latest
    nameId: 'list.sort_list_at_desc',
    value: StarSortBy.ListAtDesc
  }
]

// const - slot display order on star detail
export const StarSlotDisplayOrder = [
  OrbitalSlotType.SunSlot,
  OrbitalSlotType.AsteroidBeltSlot,
  OrbitalSlotType.CometSlot,
  OrbitalSlotType.KuiperBeltSlot,
  OrbitalSlotType.SupernovaSlot
]

// const - slot that only display half screen
export const StarSlotHalfDisplayTypes = [
  OrbitalSlotType.KuiperBeltSlot,
  OrbitalSlotType.SupernovaSlot
]

// const - auto slide duration for constellation section
export const RELATED_STAR_AUTO_SLIDE_DURATION = 10000 // milliseconds

// const - default value display when star is not found
export const EMPTY_STAR_INFO = '-'
