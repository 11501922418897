import { ROUTE } from '@src/constants/route'

export const AUTO_BELIEVE_ROUTES = [ROUTE.EXPEDITION]

export enum AdeptusCampaignType {
  StarMint = 0,
  Conjunction,
  ChronoMerge,
  Expedition,
  Trade
}

export const REWARD_CLAIM_SUCCESS_DURATION = 3000
