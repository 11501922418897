import { gql } from '@apollo/client'
import { StarFragment } from '@src/graphql/fragment/star'

export default gql`
  query GetStar($starID: String!) {
    getStar(StarID: $starID) {
      ...Star
    }
  }
  ${StarFragment}
`
