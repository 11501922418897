// enum -
export enum CONJUNCT_PAGE_STATE {
  DEFAULT = 0,
  SELECT_PLANET,
  APPROVE_CURRENCY,
  APPROVING_CURRENCY,
  CLICK_TO_CONJUNCT,
  CONJUNCTING,
  PLAY_ANIMATION,
  CONJUNCT_SUCCESS
}

// const -
export const CONJUNCTION = {
  BACKGROUND_VIDEO:
    'https://nftprops.apeironnft.com/videos/celestial_conjunction_bg_latest.mp4',
  CONJUNCT_VIDEO:
    'https://nftprops.apeironnft.com/videos/celestial_conjunction_active.mp4'
}

// const -
export enum CONJUNCT_COST_MODES {
  APRSANIMA = 'APRSANIMA',
  BLACK_HOLE = 'BLACK_HOLE'
}

export const CONJUNCT_PLANET_LIST_SIZE = 10

export const BREED_IS_PRIMEVAL = true

export const CONJUNCTION_COST_TOOLTIPS_ID = 'conjunction-cost-info'
