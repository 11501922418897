import { DEFAULT_ALT, NextImage, SkeletonTypography } from '@apeiron/library'
import { Box, styled } from '@mui/material'
import { FC } from 'react'

const Container = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

const Icon = styled(NextImage)`
  aspect-ratio: 1;
  width: 34px;
`

const PriceContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;
`

const Price = styled(SkeletonTypography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
`

const Unit = styled(SkeletonTypography)`
  color: #b1b3b3;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
`

const Token: FC<Props> = props => {
  const { amount, className, iconUrl, unit } = props

  return (
    <Container className={className}>
      <Icon src={iconUrl} alt={DEFAULT_ALT} />
      <PriceContainer>
        <Price>{amount}</Price>
        <Unit>{unit}</Unit>
      </PriceContainer>
    </Container>
  )
}

type Props = {
  amount: string
  className?: string
  iconUrl: string
  unit: string
}

export default Token
