import { useLazyQuery } from '@apollo/client'
import { NftType } from '@src/constants/bookmark'
import graphql from '@src/graphql/query/getBookmarkStatus'
import * as R from 'ramda'
import { useCallback, useState } from 'react'

const deserializeResponse = R.pathOr(false, ['data', 'isBookmarked'])

const useGetNFTBookmarkStatus = (): Hook => {
  const [isBookmarked, { loading }] = useLazyQuery(graphql)

  const [data, setData] = useState<Response>(false)

  const fetch = useCallback(
    async (request: Request): Promise<Response> => {
      const { nftType, tokenId } = request

      const response = await isBookmarked({
        variables: { nftType, tokenId }
      })

      const success = deserializeResponse(response)

      setData(success)

      return success
    },
    [isBookmarked]
  )

  return {
    fetch,
    data,
    loading
  }
}

type Request = {
  nftType: NftType
  tokenId: string
}

type Response = boolean

type Hook = {
  fetch: (request: Request) => Promise<Response>
  data: Response
  loading: boolean
}

export default useGetNFTBookmarkStatus
