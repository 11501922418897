// enum -
export enum EXPEDITION_PAGE_STATE {
  DEFAULT = 0,
  EXPAND
}

// enum -
export enum EXTRA_TOKEN_DEPOSIT_MODE {
  ADD_AND_STAKE = 0,
  REDUCE_AND_UNSTAKE
}

// enum -
export enum ExpeditionStatus {
  NOT_START = 'NOT_START',
  START = 'START',
  FINISHED = 'FINISHED',
  CLAIMABLE = 'CLAIMABLE',
  UNKNOWN = 'UNKNOWN'
}

export enum ExpeditionClaimableStatus {
  /**
   * Expedition is not claimable, can be "not started"/"started"/finished
   */
  Open = 'Open',
  /**
   * when status === "ClaimRewardPeriod", users are really able to claim rewards
   */
  ClaimRewardPeriod = 'ClaimRewardPeriod'
}

export enum ExpeditionStakeType {
  Planet = 'Planet',
  Apostle = 'Apostle',
  ApostleTicket = 'ApostleTicket',
  BasicNFT = 'BasicNFT',
  Key = 'Key',
  WETH = 'WETH',
  APRS = 'APRS',
  ANIMA = 'ANIMA',
  UNKNOWN = 'UNKNOWN'
}

// enum -
export enum TreasureKeyType {
  Cosmic = 'cosmic',
  Chaotic = 'chaotic'
}

// enum -
export enum StarTreasureType {
  Regular = 'Regular',
  Celestial = 'Celestial',
  Empyrean = 'Empyrean',
  Omega = 'Omega'
}

// enum -
export enum ExpeditionStep {
  Planet = 'planet',
  Apostle = 'apostle',
  ApostleTicket = 'apostle_ticket',
  BasicNFT = 'basic_nft',
  StartWithKey = 'start_with_key',
  StartWithoutKey = 'start_without_key',
  Result = 'result'
}

// enum -
export enum ExpeditionAssetStatus {
  Empty,
  Selected,
  Staked
}

export enum TreasureItemType {
  Star = 'fetchApi::star',
  Godiverse = 'fetchApi::godiverse'
}

export enum TreasureBoxInfoType {
  None = 'None',
  PrescaleStar = 'PresaleStar',
  Item = 'Item'
}

// const - preview expeditions +/- 1 month
export const EXPEDITION_PREVIEW_PERIOD = 1

// const -
export const EXPEDITION_PLANET_PRESET_LIMIT = 6

// const -
export const EXPEDITION_APOSTLE_PRESET_LIMIT = 12

// const -
export const EXPEDITION_TICKET_PRESET_LIMIT = 12

// const -
export const EXPEDITION_THIRD_PARTY_NFT_PRESET_LIMIT = 12

// const -
export const EXPEDITION_REWARD_LIMIT = 50

// const - treasure box (for sorting)
export const TREASURE_BOX_LEVEL = ['Omega', 'Empyrean', 'Celestial', 'Regular']

// const - expedition leaderboard display limit
export const EXPEDITION_LEADERBOARD_DISPLAY_LIMIT = 100

export const BOX_COLOR = {
  [StarTreasureType.Regular]: {
    title: `
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #ffffff;
      background-image: linear-gradient(0deg, rgba(240,240,240,1) 40%, rgba(167,167,167,1) 75%);
    `,
    divider: '#ffffff'
  },
  [StarTreasureType.Celestial]: {
    title: `
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #39d8ff;
      background-image: linear-gradient(0deg, #85ff60 30%, #33bb61 75%);
    `,
    divider: '#3affb8'
  },
  [StarTreasureType.Empyrean]: {
    title: `
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #ff822b;
      background-image: linear-gradient(0deg, rgba(161,243,255,1) 20%, rgba(0,187,255,1) 75%);
    `,
    divider: '#36a8ff'
  },
  [StarTreasureType.Omega]: {
    title: `
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #d3b4e9;
    background-image: linear-gradient(0deg, rgba(237,216,252,1) 0%, rgba(255,255,255,1) 45%);
  `,
    divider: '#eccfff'
  }
}
