import { publicAccountSchema } from '@apeiron/library'
import ENV_CONFIG from '@src/config'
import { EmptyGodiverse } from '@src/constants/godiverse'
import { ListingStatus } from '@src/constants/listingInfo'
import { OrbitalSlotType, StarStatus } from '@src/constants/star'
import { godiverseSchema } from '@src/deserialize/yup/godiverse'
import {
  listingInfoSchema,
  listingStatusSchema
} from '@src/deserialize/yup/listingInfo'
import { GodiverseLite } from '@src/types/godiverse'
import {
  CelestialObject,
  Constellation,
  ConstellationType,
  Galaxy,
  OrbitalSlot,
  PermanentSupernova,
  Star,
  StarBuff,
  StarEffect,
  StarLite,
  StarSlotsMap,
  StarSuperLite,
  StarTier
} from '@src/types/star'
import * as R from 'ramda'
import * as yup from 'yup'

const starStatusSchema: yup.SchemaOf<StarStatus> = yup
  .mixed()
  .oneOf(Object.values(StarStatus))
  .default(StarStatus.Enable)

const orbitalSlotTypeSchema: yup.SchemaOf<OrbitalSlotType> = yup
  .mixed()
  .oneOf(Object.values(OrbitalSlotType))
  .default(OrbitalSlotType.SunSlot)

export const constellationTypeSchema: yup.SchemaOf<ConstellationType> =
  yup.object({
    id: yup.number().default(0),
    name: yup.string().default('')
  })

const constellationSchema: yup.SchemaOf<Constellation> = yup
  .object({
    description: yup.string().default(''),
    digit: yup.number().default(0),
    id: yup.number().default(0),
    name: yup.string().default(''),
    image: yup.string().default(''),
    type: constellationTypeSchema
  })
  .when(['$data'], (data: Record<string, any>) => {
    return yup.object().transform((): Constellation => {
      const constellation = R.propOr(null, 'constellation', data)

      const type = R.propOr(null, 'constellationType', data)

      return {
        description: R.propOr('', 'description', constellation),
        digit: R.propOr(0, 'constellationDigit', data),
        id: R.propOr(0, 'id', constellation),
        image: `${ENV_CONFIG.DOMAIN_URL.ASSET}${R.propOr(
          '',
          'position_image',
          constellation
        )}`,
        name: R.propOr('', 'name', constellation),
        type: {
          id: R.propOr('', 'id', type),
          name: R.propOr('', 'name', type)
        }
      }
    })
  })

const galaxySchema: yup.SchemaOf<Galaxy> = yup
  .object({
    id: yup.number().default(0),
    image: yup.string().default(''),
    name: yup.string().default('')
  })
  .when(['$data'], (data: Record<string, any>) => {
    return yup.object().transform((_: any, originValue: any): Galaxy => {
      return {
        id: originValue.id || '',
        image: data.galaxyImage,
        name: originValue.name
      }
    })
  })

const celestialObjectSchema: yup.SchemaOf<CelestialObject> = yup
  .object({
    id: yup.number().default(0),
    name: yup.string().default('')
  })
  .when(['$data'], (data: Record<string, any>) => {
    return yup.object().default({
      id: data.celestialObject.id,
      name: data.celestialObject.name
    })
  })

const permanentSupernovaSchema: yup.SchemaOf<PermanentSupernova> = yup
  .object({
    id: yup.number().default(0),
    name: yup.string().default('')
  })
  .when(['$data'], (data: Record<string, any>) => {
    return yup.object().default({
      id: data.permanentSupernova.id,
      name: data.permanentSupernova.name
    })
  })

const starBuffSchema: yup.SchemaOf<StarBuff> = yup
  .object({
    agingBuff: yup.number().default(0),
    celestialObject: celestialObjectSchema,
    orbitalTrack: yup.number().default(0),
    permanentSupernova: permanentSupernovaSchema
  })
  .when(['$data'], (data: Record<string, any>) => {
    return yup.object().default({
      agingBuff: data.ageBuffValue || 0,
      celestialObject: data.celestialObject || 0,
      orbitalTrack: data.trackCountValue || 0,
      permanentSupernova: data.permanentSupernova || 0
    })
  })

const starAdditionBuffSchema: yup.SchemaOf<StarBuff> = yup
  .object({
    agingBuff: yup.number().default(0),
    celestialObject: celestialObjectSchema,
    orbitalTrack: yup.number().default(0),
    permanentSupernova: permanentSupernovaSchema
  })
  .when(['$data'], (data: Record<string, any>) => {
    return yup.object().default({
      agingBuff: data.additionAgingBuffValue || 0,
      celestialObject: 0,
      orbitalTrack: data.additionOrbitalTrackValue || 0,
      permanentSupernova: 0
    })
  })

const equippedItemsSchema: yup.SchemaOf<Array<GodiverseLite | null>> = yup
  .array()
  .of(yup.mixed().oneOf([godiverseSchema, null]))
  .when(['$data', 'type'], (data: Record<string, any>, type: any) => {
    return yup.array().default((): Array<GodiverseLite | null> => {
      const astroIds = R.pathOr([], ['slotItems', type], data)

      return R.map(id => {
        return R.isEmpty(id) ? null : { ...EmptyGodiverse, id }
      }, astroIds) as Array<GodiverseLite | null>
    })
  })
  .default([])

const orbitalSlotSchema: yup.SchemaOf<OrbitalSlot> = yup
  .object({
    count: yup.number().default(0),
    equipped: equippedItemsSchema,
    image: yup.string().default(''),
    name: yup.string().default(''),
    type: orbitalSlotTypeSchema
  })
  .noUnknown(true)

const starSlotsMapSchema: yup.SchemaOf<StarSlotsMap> = yup.object().shape({
  [OrbitalSlotType.SunSlot]: orbitalSlotSchema.nullable(),
  [OrbitalSlotType.AsteroidBeltSlot]: orbitalSlotSchema.nullable(),
  [OrbitalSlotType.KuiperBeltSlot]: orbitalSlotSchema.nullable(),
  [OrbitalSlotType.CometSlot]: orbitalSlotSchema.nullable(),
  [OrbitalSlotType.SupernovaSlot]: orbitalSlotSchema.nullable()
})

export const starEffectSchema: yup.SchemaOf<StarEffect> = yup.object({
  id: yup.number().default(0),
  image: yup.string().default(''),
  name: yup.string().default('')
})

export const tierSchema: yup.SchemaOf<StarTier> = yup.object({
  image: yup.string().default(''),
  level: yup.number().default(0),
  name: yup.string().default(''),
  type: yup.string().default('')
})

export const starSuperLiteSchema: yup.SchemaOf<StarSuperLite> = yup
  .object({
    additionBuff: starAdditionBuffSchema,
    buff: starBuffSchema,
    id: yup.string().default('0'),
    name: yup.string().default(''),
    owner: publicAccountSchema.nullable(),
    sale: yup
      .boolean()
      .transform((value: ListingStatus) => {
        return value === ListingStatus.LISTING
      })
      .default(false),
    tier: tierSchema.nullable()
  })
  .from('nftListingStatus', 'sale', true)
  .from('starID', 'id')
  .from('starName', 'name')
  .noUnknown(true)

export const starLiteSchema: yup.SchemaOf<StarLite> = starSuperLiteSchema
  .concat(
    yup.object({
      constellation: constellationSchema.nullable(),
      disabled: yup.boolean().default(false),
      galaxy: galaxySchema.nullable(),
      image: yup.string().default(''),
      listingInfo: listingInfoSchema.nullable(),
      status: starStatusSchema.nullable()
    })
  )
  .from('starImage', 'image')
  .noUnknown(true)

export const starSchema: yup.SchemaOf<Star> = starLiteSchema
  .concat(
    yup.object({
      constellation: constellationSchema.nullable(),
      isBookmarked: yup.boolean().default(false),
      nftListingStatus: listingStatusSchema,
      positionImage: yup.string().default(''),
      priceInUSD: yup.string().nullable(),
      slotsMap: starSlotsMapSchema.nullable()
    })
  )
  .from('starPositionImage', 'positionImage')
  .from('slots', 'slotsMap')
  .noUnknown(true)
