import '@src/styles/globals.css'
import '@src/util/decimal'
import '@src/util/i18n'
import 'swiper/css'
import 'ag-grid-community/styles/ag-grid.css'

import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import Layout from '@src/components/layout'
import Providers from '@src/components/layout/Providers'
import { apeironFont, arwFont, roboto } from '@src/styles/fonts'
import theme from '@src/styles/theme'
import { ComponentProp } from '@src/types/app'
import type { AppProps as NextAppProps, NextWebVitalsMetric } from 'next/app'
import * as R from 'ramda'
import { useEffect } from 'react'

const Application = (props: AppProps) => {
  const { Component, pageProps } = props

  useEffect(() => {
    const body = document.querySelector('body')

    if (R.isNotNil(body)) {
      body.classList.add(roboto.className)
      body.classList.add(apeironFont.variable)
      body.classList.add(roboto.variable)
      body.classList.add(arwFont.variable)
    }
  }, [])

  return (
    <StyledEngineProvider>
      <ThemeProvider theme={theme}>
        <Providers>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </Providers>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

type AppProps = NextAppProps & { Component: ComponentProp }

export default Application

/**
 *
 * @see https://nextjs.org/docs/advanced-features/measuring-performance
 */
export const reportWebVitals = (metric: NextWebVitalsMetric) => {
  if (metric.label === 'web-vital') {
    // only print on TTFB / FCP / LCP / FID / CLS / INP
    const duration = (metric.startTime / 1000).toFixed(2)

    console.info(`${metric.name}: ${duration}s`)
  }
}
