import { Box, styled } from '@mui/material'
import { FC, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react'

const DraggableContainer = styled(Box)``

const ApeironDraggable: FC<Props> = props => {
  const { className, onClick, children, onReposition, position, setPosition } =
    props

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent)
  }

  const [isDragging, setIsDragging] = useState(false)

  const [offset, setOffset] = useState({ x: 0, y: 0 })

  const dragImageRef = useRef<HTMLImageElement | null>(null)

  const handleDragStart = (event: any) => {
    setIsDragging(true)
    setOffset({
      x: event.clientX - position.x,
      y: event.clientY - position.y
    })

    if (dragImageRef.current) {
      event.dataTransfer.setDragImage(dragImageRef.current, 0, 0)
    }
  }

  const handleDragging = (event: MouseEvent<HTMLDivElement>) => {
    const exceedX = event.clientX - offset.x <= 0

    const exceedY = event.clientY - offset.y <= 0

    if (isDragging && !exceedX && !exceedY) {
      setPosition({
        x: event.clientX - offset.x,
        y: event.clientY - offset.y
      })
    }
  }

  const handleMotionStop = () => {
    setIsDragging(false)
  }

  const handleTouchStart = (event: any) => {
    const touch = event.touches[0]

    setIsDragging(true)
    setOffset({
      x: touch.clientX - position.x,
      y: touch.clientY - position.y
    })
  }

  const handleTouchDragging = (event: any) => {
    const touch = event.touches[0]

    const exceedX = touch.clientX <= 0 || touch.clientX >= window.innerWidth

    const exceedY = touch.clientY <= 0 || touch.clientY >= window.innerHeight

    if (isDragging && !exceedX && !exceedY) {
      setPosition({
        x: touch.clientX - offset.x,
        y: touch.clientY - offset.y
      })
    }
  }

  useEffect(() => {
    // Create the transparent image for dragging
    const img = new Image()

    img.src =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==' // 1x1 transparent gif
    dragImageRef.current = img
  }, [])

  useEffect(() => {
    if (window) {
      onReposition?.()
      if (!isMobileDevice()) {
        window.addEventListener('resize', () => {
          onReposition?.()
        })
        window.removeEventListener('resize', () => {
          onReposition?.()
        })
      }
    }
  }, [onReposition])

  return (
    <DraggableContainer
      className={className}
      style={{ left: position.x, top: position.y }}
      onClick={onClick}
      draggable
      onDragStart={handleDragStart}
      onDrag={handleDragging}
      onDragEnd={handleMotionStop}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchDragging}
      onTouchEnd={handleMotionStop}
    >
      {children}
    </DraggableContainer>
  )
}

type Props = {
  className?: string
  children?: ReactNode
  onClick?: (event: MouseEvent) => void
  onReposition?: () => void
  position: { x: number; y: number }
  setPosition: (position: { x: number; y: number }) => void
}

export default ApeironDraggable
