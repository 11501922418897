import { FrontendBountyConfig } from '@src/types/config'

const useMakeBountyConfig = () => {
  const makeBountyConfig = (frontendConfig: any): FrontendBountyConfig => {
    const renamedPostSetting = frontendConfig.bounty.apostle.post_setting.map(
      (setting: any) => ({
        currencyCode: setting.currency_code,
        minPrice: setting.min_price
      })
    )

    return {
      apostle: {
        postSetting: renamedPostSetting,
        supportedCurrencies:
          frontendConfig.bounty.apostle.support_post_currency_code
      }
    }
  }

  return {
    makeBountyConfig
  }
}

export default useMakeBountyConfig
