import { Badge, BadgeProps, styled } from '@mui/material'
import { NotifyType } from '@src/constants/notification'
import { useNotificationContext } from '@src/contexts/share/NotificationContext'
import { FC } from 'react'

const StyledBadge = styled(Badge)`
  & > .MuiBadge-badge {
    background-color: white;
    color: #333;
    z-index: 0; // don't display force on top
  }
`

const MuiBadge: FC<Props> = (props: Props) => {
  const { className, children, type, ...remainingProps } = props

  const { getNotifyMessage } = useNotificationContext()

  const notifyMessage = getNotifyMessage(type)

  return notifyMessage ? (
    <StyledBadge
      {...remainingProps}
      badgeContent={notifyMessage?.message}
      className={className}
      max={9}
      showZero={false}
    >
      {children}
    </StyledBadge>
  ) : (
    <>{children}</>
  )
}

type Props = {
  className?: string
  type: NotifyType
} & BadgeProps<any, any>

export default MuiBadge
