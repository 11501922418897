import { useAccountContext } from '@apeiron/library'
import { Box, styled, Typography } from '@mui/material'
import { FC } from 'react'

const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`

const PlayerName = styled(Typography)`
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  margin-right: auto;
  text-transform: none;
`

const PlayerTag = styled(Typography)`
  color: #b1b3b3;
  font-size: 14px;
  line-height: 16px;
  margin-right: auto;
`

const PlayerNameID: FC<Props> = props => {
  const { className } = props

  const { account } = useAccountContext()

  return (
    <TextContainer className={className}>
      <PlayerName>{account.name}</PlayerName>
      <PlayerTag>{`# ${account.tag}`}</PlayerTag>
    </TextContainer>
  )
}

type Props = {
  className?: string
}

export default PlayerNameID
