import { gql } from '@apollo/client'

export default gql`
  query GetMyNotifications($input: NotificationsInput!) {
    getMyNotifications(input: $input) {
      notifications {
        notificationType
        count
      }
    }
  }
`
