import { gql } from '@apollo/client'
import {
  GodiverseMetaLiteFragment,
  OwnerBalanceFragment
} from '@src/graphql/fragment/godiverse'
import { ListingInfoFragment } from '@src/graphql/fragment/listingInfo'

export default gql`
  query GetGodiverses($input: GetGodiversesInput!) {
    getGodiverses(input: $input) {
      godiverseMeta {
        ...GodiverseMetaLite
      }
      listingInfo {
        currencyCode
        ...ListingInfo
      }
      minListingInfo {
        currencyCode
        ...ListingInfo
      }
      ownerBalanceInfo {
        ...OwnerBalance
      }
      metaData
    }
  }
  ${GodiverseMetaLiteFragment}
  ${ListingInfoFragment}
  ${OwnerBalanceFragment}
`
