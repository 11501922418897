import { AuthLibConfig, AuthLibProvider } from '@apeiron/auth-lib'
import {
  ChainNetworkType,
  CurrencyTokenType,
  ShareLibConfig,
  ShareLibProvider
} from '@apeiron/library'
import { FractalProvider } from '@fractalwagmi/react-sdk'
import ApeironProviderSwitcher from '@src/components/share/apeiron/ApeironProviderSwitcher'
import ENV_CONFIG from '@src/config'
import { config } from '@src/config/wagmi'
import { AUTO_BELIEVE_ROUTES } from '@src/constants/adeptus'
import buildId from '@src/constants/buildId'
import { OPERATIONS_WITH_AUTH } from '@src/constants/graphql'
import { RoninNameServiceProvider } from '@src/contexts/contract/RoninNameServiceContext'
import { ElementProvider } from '@src/contexts/planet/ElementContext'
import { PlanetClassProvider } from '@src/contexts/planet/PlanetClassContext'
import { FrontendConfigProvider } from '@src/contexts/share/FrontendConfigContext'
import { FullScreenBlockingProvider } from '@src/contexts/share/FullScreenBlockingContext'
import { HistoryProvider } from '@src/contexts/share/HistoryContext'
import { NotificationProvider } from '@src/contexts/share/NotificationContext'
import { NowProvider } from '@src/contexts/share/NowContext'
import { SecondaryNavBarProvider } from '@src/contexts/share/SecondaryNavBarContext'
import { ConstellationsProvider } from '@src/contexts/star/ConstellationsContext'
import { RoninWalletSnackbarProvider } from '@src/contexts/wallet/RoninWalletSnackbarContext'
import CONTRACT from '@src/contracts/config'
import { FC, ReactNode, useMemo } from 'react'

const Providers: FC<Props> = ({ children }) => {
  const shareConfig = useMemo((): ShareLibConfig => {
    return {
      adeptus: {
        autoBelievePaths: AUTO_BELIEVE_ROUTES
      },
      apollo: {
        authOperations: OPERATIONS_WITH_AUTH,
        buildId,
        domain: ENV_CONFIG.API_URL.MARKETPLACE,
        domainLogin: ENV_CONFIG.API_URL.MARKETPLACE
      },
      chainSwitcher: {
        preferredNetwork: ChainNetworkType.Ronin,
        wagmiConfig: config
      },
      currency: {
        contractMap: {
          [CurrencyTokenType.Anima]: CONTRACT.CONJUNCT.ANIMA,
          [CurrencyTokenType.Aprs]: CONTRACT.CONJUNCT.APRS,
          [CurrencyTokenType.AprsRon]: CONTRACT.APRS_WRON,
          [CurrencyTokenType.BlackHole]: CONTRACT.CONJUNCT.BLACK_HOLE,
          [CurrencyTokenType.Unspecified]: CONTRACT.WETH,
          [CurrencyTokenType.Usdc]: CONTRACT.USDC,
          [CurrencyTokenType.Weth]: CONTRACT.WETH,
          [CurrencyTokenType.WRon]: CONTRACT.WRON
        }
      },
      fingerPrint: {
        key: ENV_CONFIG.ID.FINGERPRINT
      },
      fetchJson: {
        buildId
      },
      geeTest: {
        id: ENV_CONFIG.ID.GEETEST
      },
      pathMaker: {
        app: ENV_CONFIG.VARIABLES.NAME,
        buildId,
        domainAsset: ENV_CONFIG.DOMAIN_URL.ASSET,
        domainJson: ENV_CONFIG.DOMAIN_URL.JSON
      },
      web3: {
        wagmiConfig: config,
        walletConnectId: ENV_CONFIG.ID.WALLETCONNECT
      }
    }
  }, [])

  const authConfig = useMemo((): AuthLibConfig => {
    return {
      login: {
        external: {
          termsURL: ENV_CONFIG.EXTERNAL_URL.APEIRON.TERMS,
          privacyURL: ENV_CONFIG.EXTERNAL_URL.APEIRON.PRIVACY
        },
        sso: {
          redirectPath: ENV_CONFIG.ROUTE.SSO
        }
      },
      fetchJson: {
        buildURL: ENV_CONFIG.JSON_URL.BATTLE_DEMO_CONFIG,
        downloadPath: ENV_CONFIG.JSON_FILE_NAME.DOWNLOAD,
        ssoPath: ENV_CONFIG.JSON_FILE_NAME.SSO
      }
    }
  }, [])

  return (
    <ShareLibProvider config={shareConfig}>
      <AuthLibProvider config={authConfig}>
        <FractalProvider clientId={ENV_CONFIG.ID.FRACTAL}>
          <NowProvider>
            <FullScreenBlockingProvider>
              <NotificationProvider>
                <FrontendConfigProvider>
                  <ElementProvider>
                    <PlanetClassProvider>
                      <ConstellationsProvider>
                        <SecondaryNavBarProvider>
                          <HistoryProvider>
                            <ApeironProviderSwitcher>
                              <RoninWalletSnackbarProvider>
                                <RoninNameServiceProvider>
                                  {children}
                                </RoninNameServiceProvider>
                              </RoninWalletSnackbarProvider>
                            </ApeironProviderSwitcher>
                          </HistoryProvider>
                        </SecondaryNavBarProvider>
                      </ConstellationsProvider>
                    </PlanetClassProvider>
                  </ElementProvider>
                </FrontendConfigProvider>
              </NotificationProvider>
            </FullScreenBlockingProvider>
          </NowProvider>
        </FractalProvider>
      </AuthLibProvider>
    </ShareLibProvider>
  )
}

type Props = {
  children: ReactNode
}

export default Providers
