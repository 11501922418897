import { MuiLoading, usePreloadContext } from '@apeiron/library'
import { Box, styled } from '@mui/material'
import { PRELOAD_KEY } from '@src/constants/preload'
import { ROUTE } from '@src/constants/route'
import useGetFrontendConfig from '@src/hooks/graphql/useGetFrontendConfig'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import { FC, ReactNode, useState } from 'react'
import useAsyncEffect from 'use-async-effect'

const LoadingContainer = styled(Box)`
  align-items: center;
  background-color: #26282a;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 1;
`

const CheckMaintenance: FC<Props> = (props: Props) => {
  const { children } = props

  const { preloadValue } = usePreloadContext()

  const { fetch: fetchConfig } = useGetFrontendConfig()

  const router = useRouter()

  const [loading, setLoading] = useState<boolean>(true)

  useAsyncEffect(
    async (isActive: () => boolean) => {
      const config = await preloadValue(
        PRELOAD_KEY.FRONTEND_CONFIG,
        fetchConfig
      )

      if (!isActive()) {
        return
      }

      if (R.isNotNil(config)) {
        const extractPath = R.pipe(R.split(/[?#]/), R.propOr('', '0'))

        const currentPath = extractPath(router.asPath) as string

        config.maintenance?.forEach(item => {
          if (item.isMaintenance === false) return
          item.paths.forEach(path => {
            if (currentPath.search(path) !== -1) {
              router.push(`${ROUTE.MAINTENANCE}?${encodeURIComponent(path)}`)
            }
          })
        })
      }

      setLoading(false)
    },
    [router.asPath]
  )

  return (
    <>
      {!loading ? (
        children
      ) : (
        <LoadingContainer>
          <MuiLoading loading />
        </LoadingContainer>
      )}
    </>
  )
}

type Props = {
  children?: ReactNode
}

export default CheckMaintenance
