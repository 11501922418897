import { GodiverseLite } from '@src/types/godiverse'

// enum - godiverse list mode
export enum GodiverseListMode {
  EMPORIUM = 0,
  INVENTORY,
  OTHERS_INVENTORY,
  FAVORITE,
  POPUP_SELECT_FOR_STAR_ATTACH
}

export enum GodiverseCardType {
  NONE = 0,
  INVENTORY_NORMAL,
  INVENTORY_LISTING,
  INVENTORY_ATTACH
}

/**
 * this enum follow this
 * @see https://github.com/FoonieMagus/ApeironTokenContract/blob/dev/brian/ronin_migration/GodiverseMapping.md#mini-black-hole-item-type-0
 */
export enum GodiverseType {
  MiniBlackHole = 0,
  Sun,
  Asteroid,
  Comet,
  Supernova,
  ApostleTicket,
  Gift,
  BattlePass,
  Emblem,
  CommunityItem
}

export enum GodiverseMetadataType {
  Astronomical = 'Astronomical',
  BreedingRelic = 'Breeding',
  Item = 'Item'
}

// const - filter tag color (e.g. rarity / customization)
export const DEFAULT_FILTER_TAG_COLOR = '#dd8a0e'

export const EmptyGodiverse: GodiverseLite = {
  agingBuff: 0,
  balanceInfo: null,
  cheapestListingInfo: null,
  disabled: {
    message: '',
    value: false
  },
  id: '0',
  image: '',
  listingInfo: null,
  metadata: {
    dataType: GodiverseMetadataType.Item
  },
  name: '',
  orbitTrack: 0,
  selected: 0,
  tier: null,
  typeInfo: null
}

export const ASTRONOMICAL_BUFF = {
  COLOR: {
    AGING: {
      BUFF: '#e71c80',
      BUFF_NAME: '#fe5a36'
    },
    ORBITAL_TRACK: {
      BUFF: '#57b04f',
      BUFF_NAME: '#78a5ff'
    }
  }
}
