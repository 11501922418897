import { useEffect } from 'react'

const useApeironWindowInjection = (): Hook => {
  useEffect(() => {
    window.apeiron = {
      env: process.env.NEXT_PUBLIC_ENV || 'unknown'
    }
  }, [])
}

type Hook = void

export default useApeironWindowInjection
