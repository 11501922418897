import { apostleTicketSchema } from '@src/deserialize/yup/apostle'
import { ApostleTicket } from '@src/types/apostle'
import { useCallback } from 'react'

const useMakeApostleTicket = (): Hook => {
  const makeApostleTicket = useCallback((input: any): ApostleTicket => {
    return apostleTicketSchema.validateSync(input)
  }, [])

  return {
    makeApostleTicket
  }
}

type Hook = {
  makeApostleTicket: (input: any) => ApostleTicket
}

export default useMakeApostleTicket
