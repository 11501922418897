import { hyphenLocale, i18nNamespace } from '@apeiron/library'
import { useLazyQuery } from '@apollo/client'
import { DEFAULT_LOCALE } from '@src/constants/locale'
import useMakeApostleMintConfig from '@src/deserialize/hook/useMakeApostleMintConfig'
import useMakeBountyConfig from '@src/deserialize/hook/useMakeBountyConfig'
import { frontendConfigSchema } from '@src/deserialize/yup/config'
import graphql from '@src/graphql/query/getFrontendConfig'
import { FrontendConfig } from '@src/types/config'
import * as R from 'ramda'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useGetFrontendConfig = (): Hook => {
  const [query, { loading }] = useLazyQuery(graphql)

  const { i18n } = useTranslation()

  const [data, setData] = useState<Response>(null)

  const { makeApostleMintConfig } = useMakeApostleMintConfig()

  const { makeBountyConfig } = useMakeBountyConfig()

  const fetch = useCallback(async () => {
    const response = await query()

    try {
      const result = frontendConfigSchema.cast(
        R.pathOr({}, ['data', 'getSystemConfig', 0], response)
      ) as Response

      if (R.isNil(result)) {
        return null
      }

      const rawFrontendConfig = response.data.getSystemConfig[0].frontendConfig

      result.apostleMintConfig = makeApostleMintConfig(rawFrontendConfig)

      result.bounty = makeBountyConfig(rawFrontendConfig)

      setData(result)

      if (R.isNotEmpty(result.i18nLookup)) {
        i18n.addResourceBundle(
          hyphenLocale(DEFAULT_LOCALE),
          i18nNamespace.Backend,
          {
            ...result.i18nLookup
          },
          true,
          true
        )
      }

      return result
    } catch (e) {
      console.error(e)
    }

    return null
  }, [i18n, makeApostleMintConfig, makeBountyConfig, query])

  return {
    data,
    loading,
    fetch
  }
}

type Response = FrontendConfig | null

type Hook = {
  data: Response
  loading: boolean
  fetch: () => Promise<Response>
}

export default useGetFrontendConfig
