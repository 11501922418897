import { FetchJsonProps, useReactQueryFetchJson } from '@apeiron/library'
import ENV_CONFIG from '@src/config'
import { planetFilterDataSchema } from '@src/deserialize/yup/planetFilter'
import { PlanetFilterData } from '@src/types/planetFilter'

const useFetchPlanetFilterJson = (props?: FetchJsonProps) => {
  const { fetchNow } = props || {}

  return useReactQueryFetchJson<PlanetFilterData>({
    fetchNow,
    path: ENV_CONFIG.JSON_FILE_NAME.PLANET_FILTERS,
    deserialize: response =>
      planetFilterDataSchema.cast(response) as PlanetFilterData
  })
}

export default useFetchPlanetFilterJson
