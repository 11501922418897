import { gql } from '@apollo/client'

export const TreasurePoolFragment = gql`
  fragment TreasurePool on ExpeditionTreasurePool {
    previewInfoKey
    requireStakeKey
    stDescriptionKey
    stType
    totalRewardTreasure
  }
`
