import { createStrictContext } from '@apeiron/library'
import { Box, styled } from '@mui/material'
import { ReactNode, useState } from 'react'
type Context = {
  fullScreenBlocking: () => void
  discardFullScreenBlocking: () => void
}

const ScreenBlock = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
`

const [ContextProvider, useFullScreenBlockingContext] =
  createStrictContext<Context>('FullScreenBlocking')

export { useFullScreenBlockingContext }

export const FullScreenBlockingProvider = (props: Props) => {
  const { children } = props

  const [show, setShow] = useState<boolean>(false)

  const fullScreenBlocking = () => {
    setShow(true)
  }

  const discardFullScreenBlocking = () => {
    setShow(false)
  }

  return (
    <ContextProvider value={{ fullScreenBlocking, discardFullScreenBlocking }}>
      {children}
      {show && <ScreenBlock />}
    </ContextProvider>
  )
}

type Props = {
  children: ReactNode
}
