import { Box, Button, styled, Typography } from '@mui/material'
import { Component, FC, ReactElement, ReactNode, useCallback } from 'react'
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps
} from 'react-error-boundary'

const ErrorContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
`

const ErrorMessage = styled(Typography)`
  color: white;
  font-weight: 500;
  white-space: pre-line;
`

const RefreshButton = styled(Button)`
  font-size: 70px;
  font-weight: 300;
  aspect-ratio: 1;
  height: 60px;
`

const ErrorBoundary: FC<Props> = (props: Props) => {
  const { children, onFallbackRender, onReset } = props

  const handleOnFallbackRender = useCallback(
    (fallbackProps: FallbackProps) => {
      const { error, resetErrorBoundary } = fallbackProps

      return onFallbackRender ? (
        onFallbackRender(fallbackProps)
      ) : (
        <ErrorContainer>
          <ErrorMessage>{error.message}</ErrorMessage>
          <RefreshButton onClick={resetErrorBoundary}>↻</RefreshButton>
        </ErrorContainer>
      )
    },
    [onFallbackRender]
  )

  return (
    <ReactErrorBoundary
      FallbackComponent={handleOnFallbackRender}
      onReset={onReset}
    >
      {children}
    </ReactErrorBoundary>
  )
}

type Props = {
  children?: ReactNode
  onFallbackRender?: (
    fallbackProps: FallbackProps
  ) => ReactElement<unknown, string | FC | typeof Component> | null
  onReset?: () => void
}

export default ErrorBoundary
