import { NextImage, useAccountContext } from '@apeiron/library'
import { styled } from '@mui/material'
import ProfileImage from '@public/icons/icon-user-profile.png'
import * as R from 'ramda'
import { FC } from 'react'

const StyledNextImage = styled(NextImage)`
  align-items: center;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  width: 150px;
`

/**
 * @see https://nextjs.org/learn/seo/web-performance/lcp
 */
const PlayerIcon: FC<Props> = props => {
  const { className } = props

  const { account } = useAccountContext()

  const { avatar } = account

  const src = R.isEmpty(avatar) ? ProfileImage : avatar

  // add priority for LCP concern
  return <StyledNextImage className={className} src={src} priority />
}

type Props = {
  className?: string
}

export default PlayerIcon
