import { ChainType, deserializeListData } from '@apeiron/library'
import { useLazyQuery } from '@apollo/client'
import ENV_CONFIG from '@src/config'
import { expeditionLiteSchema } from '@src/deserialize/yup/expedition'
import graphql from '@src/graphql/query/getLatestExpeditions'
import { ExpeditionLite } from '@src/types/expedition'
import { compareAsc, isFuture, isPast } from 'date-fns'
import * as R from 'ramda'
import { useCallback, useState } from 'react'

const extractExpeditions = (
  mode: 'running' | 'upcoming',
  list: ExpeditionLite[]
): Record<number, ExpeditionLite> => {
  const filterCondition =
    mode === 'running'
      ? (expedition: ExpeditionLite) => isPast(new Date(expedition.startTime))
      : (expedition: ExpeditionLite) => isFuture(new Date(expedition.startTime))

  const sortCondition = (a: ExpeditionLite, b: ExpeditionLite) =>
    mode === 'running'
      ? compareAsc(new Date(b.startTime), new Date(a.startTime))
      : compareAsc(new Date(a.startTime), new Date(b.startTime))

  const extract = R.pipe<any, any, ExpeditionLite[], ExpeditionLite[]>(
    R.groupBy((expedition: ExpeditionLite) => String(expedition.type)),
    R.map((groupedByType: ExpeditionLite[]) => {
      const extractInGroup = R.pipe<
        any,
        ExpeditionLite[],
        ExpeditionLite[],
        ExpeditionLite
      >(R.filter(filterCondition), R.sort(sortCondition), R.head)

      return extractInGroup(groupedByType)
    }),
    R.reject(R.isNil)
  )

  return extract(list)
}

const useGetLatestExpeditions = (): Hook => {
  const [getLatestExpeditions, { loading }] = useLazyQuery(graphql, {
    context: { domain: ENV_CONFIG.API_URL.EXPEDITION }
  })

  const [data, setData] = useState<ExpeditionLite[]>([])

  const [upcomeData, setUpcomeData] = useState<Record<number, ExpeditionLite>>(
    {}
  )

  const fetch = useCallback(async (): Promise<Response> => {
    const response = await getLatestExpeditions({
      variables: { input: { network: ChainType.Ronin } }
    })

    const expeditionList = deserializeListData<ExpeditionLite>(
      response,
      expeditionLiteSchema
    )

    const running = R.values(
      extractExpeditions('running', expeditionList)
    ) as ExpeditionLite[]

    const upcoming = extractExpeditions('upcoming', expeditionList)

    setData(running)
    setUpcomeData(upcoming)

    return running
  }, [getLatestExpeditions])

  return {
    fetch,
    data,
    upcomeData,
    loading
  }
}

type Response = ExpeditionLite[]

type Hook = {
  fetch: () => Promise<Response>
  data: ExpeditionLite[]
  upcomeData: Record<number, ExpeditionLite>
  loading: boolean
}

export default useGetLatestExpeditions
