import { DEFAULT_ALT, NextImage } from '@apeiron/library'
import { IconButton, styled, Tooltip } from '@mui/material'
import CopySVG from '@public/icons/icon-copy.svg'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

const StyledIconButton = styled(IconButton)`
  padding: unset;
`

const CopyImage = styled(NextImage)`
  width: 18px;
  aspect-ratio: 1;
`

const CopyButton: FC<Props> = (props: Props) => {
  const { className, value } = props

  const { t } = useTranslation()

  const [copyMessage, setCopyMessage] = useState('')

  const handleOnClick = useCallback(() => {
    navigator.clipboard.writeText(value)
    setCopyMessage(t('common.button.copied'))
    setTimeout(() => {
      setCopyMessage(t('common.button.copy'))
    }, 2000)
  }, [t, value])

  return (
    <Tooltip title={copyMessage} placement='top'>
      <StyledIconButton className={className} onClick={handleOnClick}>
        <CopyImage src={CopySVG} alt={DEFAULT_ALT} />
      </StyledIconButton>
    </Tooltip>
  )
}

type Props = {
  className?: string
  value: string
}

export default CopyButton
