import { AnyObject } from '@apeiron/library'
import { NotificationDataType } from '@src/constants/notification'
import { NotificationData } from '@src/types/notification'
import * as R from 'ramda'
import { useCallback } from 'react'

const useMakeNotification = (): Hook => {
  const makeNotification = useCallback(
    (result: AnyObject): NotificationData => {
      return {
        type: R.propOr(
          NotificationDataType.DailyCheckIn,
          'notificationType',
          result
        ),
        count: R.propOr(0, 'count', result)
      }
    },
    []
  )

  const makeNotifications = useCallback(
    (result: AnyObject): NotificationData[] => {
      const input = R.propOr([], 'notifications', result) as AnyObject[]

      return R.map((result: AnyObject) => {
        return makeNotification(result)
      }, input)
    },
    [makeNotification]
  )

  return {
    makeNotification,
    makeNotifications
  }
}

type Hook = {
  makeNotification: (result: AnyObject) => NotificationData
  makeNotifications: (result: AnyObject) => NotificationData[]
}

export default useMakeNotification
