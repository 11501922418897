import { gql } from '@apollo/client'

export const OwnerFragment = gql`
  fragment Owner on Account {
    avatar
    name
    tag
    walletAddress
    createdAt
  }
`

export const PublicOwnerFragment = gql`
  fragment PublicOwner on PublicAccount {
    avatar
    name
    tag
    walletAddress
    createdAt
  }
`
