import {
  polygonAdeptusABI,
  polygonAnimaABI,
  polygonApostleABI,
  polygonApostleTicketOriginABI,
  polygonApostleTicketS2ABI,
  polygonAprsABI,
  polygonBattleDemoBurnTimeABI,
  polygonBornABI,
  polygonBreedABI,
  polygonDailyCheckInABI,
  polygonExpeditionPrizesType1ABI,
  polygonExpeditionPrizesType3ABI,
  polygonExpeditionPrizesType4ABI,
  polygonExpeditionPrizesType5ABI,
  polygonExpeditionStakingAndKeysType1ABI,
  polygonExpeditionStakingAndKeysType2ABI,
  polygonExpeditionStakingAndKeysType3ABI,
  polygonExpeditionStakingAndKeysType4ABI,
  polygonExpeditionStakingAndKeysType5ABI,
  polygonExpeditionType1ABI,
  polygonExpeditionType2ABI,
  polygonExpeditionType3ABI,
  polygonExpeditionType4ABI,
  polygonExpeditionType5ABI,
  polygonFragmentABI,
  polygonGodiverseABI,
  polygonMarketplace721ABI,
  polygonMarketplace1155ABI,
  polygonMintApostleSeasonCallerABI,
  polygonMintApostleTicketABI,
  polygonPlanetABI,
  polygonStarABI,
  polygonStarAttachABI,
  polygonStarOrbitalTrackABI,
  polygonStarOrbitalTrackCallerABI,
  polygonUsdcABI,
  polygonVestingABI,
  polygonWethABI,
  roninAdeptusABI,
  roninAnimaABI,
  roninApeironMarketplaceGatewayProxyABI,
  roninApostleABI,
  roninApostleBountyABI,
  roninApostleManage,
  roninApostleTicketOriginABI,
  roninApostleTicketS2ABI,
  roninAprsABI,
  roninAprsWronABI,
  roninBattleDemoBurnTimeABI,
  roninBornABI,
  roninBreedABI,
  roninDailyCheckInABI,
  roninExpeditionPrizesType1ABI,
  roninExpeditionPrizesType3ABI,
  roninExpeditionPrizesType4ABI,
  roninExpeditionPrizesType5ABI,
  roninExpeditionPrizesType6ABI,
  roninExpeditionPrizesType7ABI,
  roninExpeditionPrizesType8ABI,
  roninExpeditionStakingAndKeysType1ABI,
  roninExpeditionStakingAndKeysType2ABI,
  roninExpeditionStakingAndKeysType3ABI,
  roninExpeditionStakingAndKeysType4ABI,
  roninExpeditionStakingAndKeysType5ABI,
  roninExpeditionStakingAndKeysType6ABI,
  roninExpeditionStakingAndKeysType7ABI,
  roninExpeditionStakingAndKeysType8ABI,
  roninExpeditionType1ABI,
  roninExpeditionType2ABI,
  roninExpeditionType3ABI,
  roninExpeditionType4ABI,
  roninExpeditionType5ABI,
  roninExpeditionType6ABI,
  roninExpeditionType7ABI,
  roninExpeditionType8ABI,
  roninFragmentABI,
  roninGodiverseABI,
  roninLiquidityPermissionedRouterABI,
  roninMarketGatewayProxyABI,
  roninMintApostleSeasonCallerABI,
  roninMintApostleTicketABI,
  roninNFTABI,
  roninPlanetABI,
  roninPlanetApostleForgeABI,
  roninStarABI,
  roninStarAttachABI,
  roninStarOrbitalTrackABI,
  roninStarOrbitalTrackCallerABI,
  roninUsdcABI,
  roninVestingABI,
  roninVIPTokenStakeABI,
  roninWethABI,
  roninWronABI
} from '@apeiron/library'

const ABI = {
  ADEPTUS: {
    POLYGON: polygonAdeptusABI,
    RONIN: roninAdeptusABI
  },
  APOSTLE: {
    INFO: {
      POLYGON: polygonApostleABI,
      RONIN: roninApostleABI
    },
    MINT: {
      POLYGON: polygonMintApostleSeasonCallerABI,
      RONIN: roninMintApostleSeasonCallerABI
    },
    MANAGE: {
      POLYGON: [],
      RONIN: roninApostleManage
    },
    FORGE: {
      POLYGON: [],
      RONIN: roninPlanetApostleForgeABI
    }
  },
  APOSTLE_TICKET: {
    ORIGIN: {
      POLYGON: polygonApostleTicketOriginABI,
      RONIN: roninApostleTicketOriginABI
    },
    S2: {
      POLYGON: polygonApostleTicketS2ABI,
      RONIN: roninApostleTicketS2ABI
    },
    MINT: {
      POLYGON: polygonMintApostleTicketABI,
      RONIN: roninMintApostleTicketABI
    }
  },
  BATTLE_DEMO: {
    BURN_TIME: {
      POLYGON: polygonBattleDemoBurnTimeABI,
      RONIN: roninBattleDemoBurnTimeABI
    },
    FRAGMENT: {
      POLYGON: polygonFragmentABI,
      RONIN: roninFragmentABI
    }
  },
  BOUNTY: {
    APOSTLE: {
      POLYGON: [],
      RONIN: roninApostleBountyABI
    }
  },
  CURRENCY: {
    ANIMA: {
      POLYGON: polygonAnimaABI,
      RONIN: roninAnimaABI
    },
    APRS: {
      POLYGON: polygonAprsABI,
      RONIN: roninAprsABI
    },
    USDC: {
      POLYGON: polygonUsdcABI,
      RONIN: roninUsdcABI
    },
    WETH: {
      POLYGON: polygonWethABI,
      RONIN: roninWethABI
    },
    WRON: {
      POLYGON: [], // polygonWronABI,
      RONIN: roninWronABI
    },
    APRS_WRON: {
      POLYGON: [], // polygonAprsWronABI,
      RONIN: roninAprsWronABI
    }
  },
  DAILY_CHECK_IN: {
    POLYGON: polygonDailyCheckInABI,
    RONIN: roninDailyCheckInABI
  },
  EXPEDITION: {
    TYPE1: {
      PRIZE: {
        POLYGON: polygonExpeditionPrizesType1ABI,
        RONIN: roninExpeditionPrizesType1ABI
      },
      KEY: {
        POLYGON: polygonExpeditionStakingAndKeysType1ABI,
        RONIN: roninExpeditionStakingAndKeysType1ABI
      },
      INFO: {
        POLYGON: polygonExpeditionType1ABI,
        RONIN: roninExpeditionType1ABI
      }
    },
    TYPE2: {
      PRIZE: {
        POLYGON: polygonExpeditionPrizesType1ABI,
        RONIN: roninExpeditionPrizesType1ABI
      },
      KEY: {
        POLYGON: polygonExpeditionStakingAndKeysType2ABI,
        RONIN: roninExpeditionStakingAndKeysType2ABI
      },
      INFO: {
        POLYGON: polygonExpeditionType2ABI,
        RONIN: roninExpeditionType2ABI
      }
    },
    TYPE3: {
      PRIZE: {
        POLYGON: polygonExpeditionPrizesType3ABI,
        RONIN: roninExpeditionPrizesType3ABI
      },
      KEY: {
        POLYGON: polygonExpeditionStakingAndKeysType3ABI,
        RONIN: roninExpeditionStakingAndKeysType3ABI
      },
      INFO: {
        POLYGON: polygonExpeditionType3ABI,
        RONIN: roninExpeditionType3ABI
      }
    },
    TYPE4: {
      PRIZE: {
        POLYGON: polygonExpeditionPrizesType4ABI,
        RONIN: roninExpeditionPrizesType4ABI
      },
      KEY: {
        POLYGON: polygonExpeditionStakingAndKeysType4ABI,
        RONIN: roninExpeditionStakingAndKeysType4ABI
      },
      INFO: {
        POLYGON: polygonExpeditionType4ABI,
        RONIN: roninExpeditionType4ABI
      }
    },
    TYPE5: {
      PRIZE: {
        POLYGON: polygonExpeditionPrizesType5ABI,
        RONIN: roninExpeditionPrizesType5ABI
      },
      KEY: {
        POLYGON: polygonExpeditionStakingAndKeysType5ABI,
        RONIN: roninExpeditionStakingAndKeysType5ABI
      },
      INFO: {
        POLYGON: polygonExpeditionType5ABI,
        RONIN: roninExpeditionType5ABI
      }
    },
    TYPE6: {
      PRIZE: {
        POLYGON: [], // polygonExpeditionPrizesType6ABI,
        RONIN: roninExpeditionPrizesType6ABI
      },
      KEY: {
        POLYGON: [], // polygonExpeditionStakingAndKeysType6ABI,
        RONIN: roninExpeditionStakingAndKeysType6ABI
      },
      INFO: {
        POLYGON: [], // polygonExpeditionType6ABI,
        RONIN: roninExpeditionType6ABI
      }
    },
    TYPE7: {
      PRIZE: {
        POLYGON: [], // polygonExpeditionPrizesType7ABI,
        RONIN: roninExpeditionPrizesType7ABI
      },
      KEY: {
        POLYGON: [], // polygonExpeditionStakingAndKeysType7ABI,
        RONIN: roninExpeditionStakingAndKeysType7ABI
      },
      INFO: {
        POLYGON: [], // polygonExpeditionType7ABI,
        RONIN: roninExpeditionType7ABI
      }
    },
    TYPE8: {
      PRIZE: {
        POLYGON: [], // polygonExpeditionPrizesType8ABI,
        RONIN: roninExpeditionPrizesType8ABI
      },
      KEY: {
        POLYGON: [], // polygonExpeditionStakingAndKeysType8ABI,
        RONIN: roninExpeditionStakingAndKeysType8ABI
      },
      INFO: {
        POLYGON: [], // polygonExpeditionType8ABI,
        RONIN: roninExpeditionType8ABI
      }
    }
  },
  GODIVERSE: {
    POLYGON: polygonGodiverseABI,
    RONIN: roninGodiverseABI
  },
  LIQUIDITY: {
    PERMISSIONED_ROUTER: {
      POLYGON: [],
      RONIN: roninLiquidityPermissionedRouterABI
    }
  },
  MARKETPLACE: {
    MKTP721: {
      POLYGON: polygonMarketplace721ABI,
      RONIN: [] // roninMarketplace721ABI
    },
    MKTP1155: {
      POLYGON: polygonMarketplace1155ABI,
      RONIN: [] // roninMarketplace1155ABI
    },
    APEIRON_MARKETPLACE_GATEWAY_PROXY: {
      POLYGON: [],
      RONIN: roninApeironMarketplaceGatewayProxyABI
    },
    MARKET_GATEWAY_PROXY: {
      POLYGON: [],
      RONIN: roninMarketGatewayProxyABI
    }
  },
  PLANET: {
    BORN: {
      POLYGON: polygonBornABI,
      RONIN: roninBornABI
    },
    BREED: {
      POLYGON: polygonBreedABI,
      RONIN: roninBreedABI
    },
    INFO: {
      POLYGON: polygonPlanetABI,
      RONIN: roninPlanetABI
    }
  },
  STAR: {
    ATTACH_GODIVERSE: {
      POLYGON: polygonStarAttachABI,
      RONIN: roninStarAttachABI
    },
    INFO: {
      POLYGON: polygonStarABI,
      RONIN: roninStarABI
    }
  },
  THIRD_PARTY_NFT: {
    INFO: {
      POLYGON: [],
      RONIN: roninNFTABI
    }
  },
  ORBITAL_TRACK: {
    CALLER: {
      POLYGON: polygonStarOrbitalTrackCallerABI,
      RONIN: roninStarOrbitalTrackCallerABI
    },
    INFO: {
      POLYGON: polygonStarOrbitalTrackABI,
      RONIN: roninStarOrbitalTrackABI
    }
  },
  REWARD: {
    VESTING: {
      POLYGON: polygonVestingABI,
      RONIN: roninVestingABI
    }
  },
  VIP: {
    TOKEN_STAKING: {
      POLYGON: [],
      RONIN: roninVIPTokenStakeABI
    }
  }
}

export default ABI
