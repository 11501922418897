import { LoginErrorProvider, LoginView } from '@apeiron/auth-lib'
import {
  CustomError,
  ERROR_CODE,
  useAccountContext,
  useErrorPopup,
  WalletType
} from '@apeiron/library'
import { useCallback } from 'react'

const LoginPopup = () => {
  const { setMissWallet, setWrongNetwork } = useAccountContext()

  const { showErrorPopup } = useErrorPopup()

  const handleWalletLoginError = useCallback(
    (e: unknown, data?: { walletType: WalletType }) => {
      const error = e as CustomError

      if (!data?.walletType) {
        showErrorPopup(error)

        return
      }

      switch (error.code) {
        case ERROR_CODE.LOGIN.WRONG_NETWORK:
          setWrongNetwork(data.walletType)

          break

        case ERROR_CODE.LOGIN.NOT_YET_INSTALL_WALLET:
          setMissWallet(data.walletType)

          break

        default:
          showErrorPopup(error)

          break
      }
    },
    [setMissWallet, setWrongNetwork, showErrorPopup]
  )

  const handleQrCodeLoginError = useCallback(
    (e: unknown) => {
      const error = e as CustomError

      showErrorPopup(error)
    },
    [showErrorPopup]
  )

  return (
    <LoginErrorProvider
      onWalletLoginError={handleWalletLoginError}
      onQrCodeLoginError={handleQrCodeLoginError}
    >
      <LoginView />
    </LoginErrorProvider>
  )
}

export default LoginPopup
