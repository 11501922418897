import Script from 'next/script'
import { FC } from 'react'

/**
 * to update
 * @see https://static.geetest.com/v4/gt4.js
 */

const GeetestScript: FC<Props> = () => {
  return <Script src={'/script/geetest4.js'} />
}

type Props = {}

export default GeetestScript
