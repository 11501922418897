import ArcanePNG from '@public/images/background-planet-card-arcane.png'
import DivinePNG from '@public/images/background-planet-card-divine.png'
import ElementalPNG from '@public/images/background-planet-card-elemental.png'
import MythicPNG from '@public/images/background-planet-card-mythic.png'
import NormalPNG from '@public/images/background-planet-card-normal.png'
import NormalHoverPNG from '@public/images/background-planet-card-normal-hover.png'
import PrimalPNG from '@public/images/background-planet-card-primal.png'
import { SortByItem } from '@src/types/filter'
import { PlanetCardBackgroundMap } from '@src/types/planet'

// enum -
export enum PlanetSortBy {
  PlanetIdAsc = 'PlanetIDAsc',
  PlanetIdDesc = 'PlanetIDDesc',
  PriceAsc = 'PriceAsc',
  PriceDesc = 'PriceDesc',
  ListAtDesc = 'ListingTimeDesc',
  UpdateAtDesc = 'UpdateAtDesc',
  PlanetTypeAsc = 'PlanetTypeAsc',
  PlanetTypeDesc = 'PlanetTypeDesc'
}

// enum -
export enum PlanetStage {
  Planet = 'Reveal',
  Core = 'Core'
}

// enum -
export enum PlanetListMode {
  TRADE = 0,
  INVENTORY,
  SEED_INVENTORY,
  OTHERS_INVENTORY,
  FAVORITE
}

// enum -
export enum PlanetStatus {
  Enable = 'Enable',
  Staked = 'Staked',
  StakedToStar = 'StakedToStar',
  Disable = 'Disable'
}

// enum -
export enum PlanetType {
  Inferno = 'Inferno',
  Gigas = 'Gigas',
  Leviathan = 'Leviathan',
  Tempest = 'Tempest',
  Archipelago = 'Archipelago',
  Volcanic = 'Volcanic',
  Karsts = 'Karsts',
  Tundra = 'Tundra',
  Delta = 'Delta',
  Dunes = 'Dunes',
  Mountainous = 'Mountainous',
  Foresty = 'Foresty',
  Wasteland = 'Wasteland',
  Oasis = 'Oasis',
  Normal = 'Normal'
}

// enum -
export enum PlanetSkillType {
  Active = 'active_skills',
  Passive = 'passive_skills',
  PlanetPassive = 'planet_passive_skills',
  PlanetEnergize = 'planet_energized_skills',
  Binded = 'binded',
  AutoAttack = 'auto_attack'
}

// enum -
export enum SkillFilterMode {
  Active = 0,
  Passive,
  Binded // planet passive or planet energize
}

// enum -
export enum PlanetClassType {
  All = -1,
  Fury,
  Wisdom,
  Fate
}

// enum -
export enum ElementType {
  Fire = 'fire',
  Water = 'water',
  Air = 'air',
  Earth = 'earth',
  None = 'none'
}

// enum -
export enum CoreType {
  Normal = -1,
  Primal = 0,
  Divine = 1,
  Arcane = 2,
  Mythic = 3,
  Elemental = 4,
  None = 999
}

// enum - use on layout only
export enum PlanetCardInfoType {
  None = -1,
  Conjunction,
  Age,
  Seed
}

// const - use on sorting general planet list
export const PlanetNormalSortItems: SortByItem<PlanetSortBy>[] = [
  { id: 0, nameId: 'list.sort_price_asc', value: PlanetSortBy.PriceAsc },
  { id: 1, nameId: 'list.sort_price_desc', value: PlanetSortBy.PriceDesc },
  {
    id: 2,
    nameId: 'list.sort_id_asc',
    value: PlanetSortBy.PlanetIdAsc
  },
  {
    id: 3,
    nameId: 'list.sort_id_desc',
    value: PlanetSortBy.PlanetIdDesc
  },
  {
    id: 4,
    nameId: 'list.sort_updated_at_desc',
    value: PlanetSortBy.UpdateAtDesc
  },
  {
    id: 5,
    nameId: 'list.sort_planet_type_asc',
    value: PlanetSortBy.PlanetTypeAsc
  },
  {
    id: 6,
    nameId: 'list.sort_planet_type_desc',
    value: PlanetSortBy.PlanetTypeDesc
  }
]

// const - use on sorting emporium planet list
export const PlanetEmporiumSortItems: SortByItem<PlanetSortBy>[] = [
  { id: 0, nameId: 'list.sort_price_asc', value: PlanetSortBy.PriceAsc },
  { id: 1, nameId: 'list.sort_price_desc', value: PlanetSortBy.PriceDesc },
  {
    id: 2,
    nameId: 'list.sort_id_asc',
    value: PlanetSortBy.PlanetIdAsc
  },
  {
    id: 3,
    nameId: 'list.sort_id_desc',
    value: PlanetSortBy.PlanetIdDesc
  },
  {
    id: 4,
    nameId: 'list.sort_list_at_desc',
    value: PlanetSortBy.ListAtDesc
  },
  {
    id: 5,
    nameId: 'list.sort_planet_type_asc',
    value: PlanetSortBy.PlanetTypeAsc
  },
  {
    id: 6,
    nameId: 'list.sort_planet_type_desc',
    value: PlanetSortBy.PlanetTypeDesc
  }
]

// const - use on sorting planets selection
export const PlanetSelectSortItems: SortByItem<PlanetSortBy>[] = [
  {
    id: 0,
    nameId: 'list.sort_id_asc',
    value: PlanetSortBy.PlanetIdAsc
  },
  {
    id: 1,
    nameId: 'list.sort_id_desc',
    value: PlanetSortBy.PlanetIdDesc
  },
  {
    id: 2,
    nameId: 'list.sort_updated_at_desc',
    value: PlanetSortBy.UpdateAtDesc
  },
  {
    id: 3,
    nameId: 'list.sort_planet_type_asc',
    value: PlanetSortBy.PlanetTypeAsc
  },
  {
    id: 4,
    nameId: 'list.sort_planet_type_desc',
    value: PlanetSortBy.PlanetTypeDesc
  }
]

// const - age passed per day
export const AGE_PASSED_PER_DAY = 20

// const - decimal places to display planet year
export const PLANET_YEAR_DECIMAL = 2

// const - planet card background image map
export const PLANET_CARD_BACKGROUND_MAP: PlanetCardBackgroundMap = {
  [CoreType.Primal]: {
    normal: PrimalPNG.src,
    hover: PrimalPNG.src
  },
  [CoreType.Divine]: {
    normal: DivinePNG.src,
    hover: DivinePNG.src
  },
  [CoreType.Arcane]: {
    normal: ArcanePNG.src,
    hover: ArcanePNG.src
  },
  [CoreType.Mythic]: {
    normal: MythicPNG.src,
    hover: MythicPNG.src
  },
  [CoreType.Elemental]: {
    normal: ElementalPNG.src,
    hover: ElementalPNG.src
  },
  [CoreType.Normal]: {
    normal: NormalPNG.src,
    hover: NormalHoverPNG.src
  },
  [CoreType.None]: {
    normal: NormalPNG.src,
    hover: NormalHoverPNG.src
  }
}

export const MAX_PLANET_NAME = 20
