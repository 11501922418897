import { useAccountContext } from '@apeiron/library'
import { useMutation } from '@apollo/client'
import gql from '@src/graphql/mutation/updateAccountName'
import * as R from 'ramda'
import { useCallback, useState } from 'react'

export const deserializeResponse = R.pathOr(false, ['data', 'updateName'])

const useUpdateAccountName = (): Hook => {
  const [updateAccountName, { loading }] = useMutation(gql)

  const { setAccount } = useAccountContext()

  const [data, setData] = useState<Response>(false)

  const mutate = useCallback(
    async (request: Request): Promise<Response> => {
      const { name, tag } = request

      const response = await updateAccountName({
        variables: {
          name,
          tag
        }
      })

      const result = deserializeResponse(response)

      setData(result)

      if (result) {
        setAccount(account => ({
          ...account,
          name,
          tag: Number(tag)
        }))
      }

      return result
    },
    [setAccount, updateAccountName]
  )

  return {
    mutate,
    data,
    loading
  }
}

type Request = {
  name: string
  tag: string
}

type Response = boolean

type Hook = {
  mutate: (request: Request) => Promise<Response>
  data: Response
  loading: boolean
}

export default useUpdateAccountName
