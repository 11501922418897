export type Option = {
  isLite: boolean
}

// deserialize option for planet lite
export enum PlanetLiteDeserializeMode {
  DEFAULT = 0,
  ATTACH_BY_APOSTLE,
  ATTACH_TO_ORBIT,
  CONJUNCTION,
  EMPORIUM, // trade
  EXPEDITION,
  INVENTORY_AND_FAVORITE
}

export type PlanetLiteDeserializeOption = {
  cooldown?: number // in seconds
  mode?: PlanetLiteDeserializeMode
  isPrimeval?: boolean
  isSeed?: boolean
  walletAddress?: string
}
