import { useAccountContext } from '@apeiron/library'
import { useMutation } from '@apollo/client'
import gql from '@src/graphql/mutation/updateAccountNotification'
import * as R from 'ramda'
import { useCallback, useState } from 'react'

export const deserializeResponse = R.pathOr(false, [
  'data',
  'updateEmailNotificationSetting'
])

const useUpdateAccountNotification = (): Hook => {
  const [updateAccountNotification, { loading }] = useMutation(gql)

  const { setAccount } = useAccountContext()

  const [data, setData] = useState<Response>(false)

  const mutate = useCallback(
    async (request: Request): Promise<Response> => {
      const { assetSold, gameUpdate, marketingEvent } = request

      const response = await updateAccountNotification({
        variables: {
          input: {
            enableAssetSold: assetSold,
            enableGameUpdates: gameUpdate,
            enableMarketingEvent: marketingEvent
          }
        }
      })

      const result = deserializeResponse(response)

      setData(result)

      if (result) {
        setAccount(account => ({
          ...account,
          notification: { assetSold, gameUpdate, marketingEvent }
        }))
      }

      return result
    },
    [setAccount, updateAccountNotification]
  )

  return {
    mutate,
    data,
    loading
  }
}

type Request = {
  assetSold: boolean
  gameUpdate: boolean
  marketingEvent: boolean
}

type Response = boolean

type Hook = {
  mutate: (request: Request) => Promise<Response>
  data: Response
  loading: boolean
}

export default useUpdateAccountNotification
