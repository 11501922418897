import {
  CustomError,
  ERROR_CODE,
  useAccountContext,
  useErrorHandler,
  useMessageContext,
  WalletType
} from '@apeiron/library'
import { useCallback } from 'react'

const useMktErrorHandling = () => {
  const { getErrorMessage } = useErrorHandler()

  const { showGeneralError } = useMessageContext()

  const { setMissWallet } = useAccountContext()

  const showErrorPopup = useCallback(
    (error: unknown) => {
      if (
        error instanceof CustomError &&
        error.code === ERROR_CODE.LOGIN.NOT_YET_INSTALL_WALLET
      ) {
        setMissWallet(WalletType.Ronin)

        return
      }

      showGeneralError(getErrorMessage(error))
    },
    [getErrorMessage, showGeneralError, setMissWallet]
  )

  return {
    showErrorPopup
  }
}

export default useMktErrorHandling
